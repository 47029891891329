import React, { FC, Fragment } from 'react';
import { Stack } from '@mui/material';
import { StyledIndex, StyledLabel, StyledStepContainer } from './FlowSteps.styles';
import Line from 'src/components/Line';

type FlowStepsProps = {
    activeStepKey: string;
    steps: Array<{
        key: string;
        label: string;
    }>;
};

const isActive = (activeStepKey: string, steps: FlowStepsProps['steps'], index: number) => {
    return index <= steps.findIndex(({ key }) => key === activeStepKey);
};

const FlowSteps: FC<FlowStepsProps> = ({
    activeStepKey,
    steps,
}) => {
    return (
        <Stack flexDirection="row" justifyContent="space-between">
            {steps.map(({ key, label }, index) => (
                <Fragment key={key}>
                    {index !== 0 && (
                        <Stack flex={1} justifyContent="center" paddingX={{ xs: 1.5, sm: 2 }}>
                            <Line variant="horizontal" />
                        </Stack>
                    )}
                    <StyledStepContainer
                        data-is-active={isActive(activeStepKey, steps, index)}
                        flexDirection="row"
                        maxWidth="max-content"
                        alignItems="center"
                        justifyContent="center"
                        gap={1}
                        flex={100}
                    >
                        <StyledIndex>
                            {index + 1}
                        </StyledIndex>
                        <StyledLabel>
                            {label}
                        </StyledLabel>
                    </StyledStepContainer>
                </Fragment>
            ))}
        </Stack>
    );
};

export default FlowSteps;
