import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { StyledStack, TabsContainer } from './InvoiceModals.styles';
import { AttachExistingInvoice, CreateNewInvoice, InvoiceModalContent } from './components';
import { TabBarVariants } from 'src/components/TabBar/TabBar';
import { createSubscriptionBillingInvoice } from 'src/services/sso-api';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';
import StyledTooltip from 'src/components/Tooltip';
import { StripeInvoice } from 'src/@types/sso-api';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { updateBillingRecord } from 'src/services/subscription-service-api';
import { InvoiceStatus, SnackbarMessageVariants } from 'src/constants';
import { BillingRecordModel, BillingRecordWithRenewal } from 'src/@types/subscription-service-api';
import { TabBar } from 'src/components/TabBar';

export type AttachAnInvoiceModalProps = {
    billingRecord: BillingRecordWithRenewal;
};

export enum InvoiceTabType {
    CREATE_A_NEW_ONE = 'CreateANewOne',
    ATTACH_EXISTING = 'AttachExisting',
}

const AttachAnInvoiceModal: FC<AttachAnInvoiceModalProps> = ({ billingRecord: initilBillingRecord }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [invoiceData, setInvoiceData] = useState<StripeInvoice | null>(null);
    const [billingRecord, setBillingRecord] = useState<BillingRecordWithRenewal>(initilBillingRecord);

    const { addMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();
    const { closeModal } = useGeneralModal();
    const {
        priceInCents,
        subscriptionUUID,
        id,
        invoiceStatus,
        stripeInvoiceID,
    } = billingRecord;

    const isCreateInvoiceDisabled = invoiceStatus === InvoiceStatus.PAID && !stripeInvoiceID;

    const [selectedTab, setSelectedTab] = useState<InvoiceTabType>(
        isCreateInvoiceDisabled ? InvoiceTabType.ATTACH_EXISTING : InvoiceTabType.CREATE_A_NEW_ONE,
    );

    const handleTabChange = (newValue: string | number) => {
        setSelectedTab(newValue as InvoiceTabType);
    };

    const updateBillingRecordData = (updateData: Partial<BillingRecordModel>) => {
        setIsLoading(true);
        return updateBillingRecord(subscriptionUUID, id, updateData)
            .then((response) => {
                setBillingRecord(response);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSave = (data: Partial<BillingRecordModel>) => {
        setBillingRecord((prevRecord) => ({
            ...prevRecord,
            ...data,
        }));
    };

    const handleAttachInvoice = () => {
        if (!invoiceData) {
            return;
        }

        setIsLoading(true);
        return updateBillingRecordData({
            stripeInvoiceID: invoiceData.id,
            invoiceStatus: invoiceData.status,
        })
            .then(() => {
                reloadPage();
                addMessage('Invoice successfully attached', SnackbarMessageVariants.SUCCESS);
                closeModal();
            })
            .catch(() => {
                addMessage('Failed to attach an invoice', SnackbarMessageVariants.WARNING);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleCreateInvoice = () => {
        setIsLoading(true);
        return createSubscriptionBillingInvoice(subscriptionUUID, id, priceInCents)
            .then(() => {
                reloadPage();
                addMessage('Invoice successfully created', SnackbarMessageVariants.SUCCESS);
                closeModal();
            })
            .catch(() => {
                addMessage('Failed to create an invoice', SnackbarMessageVariants.WARNING);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <StyledStack>
            {isLoading && <LoaderOverlay />}
            <TabsContainer padding={0.5} marginBottom={{ xs: 2, sm: 3 }} data-testid="tabs-container">
                <StyledTooltip
                    arrow
                    title={isCreateInvoiceDisabled ? 'Creating an invoice is not allowed for this billing record' : ''}
                    disableHoverListener={!isCreateInvoiceDisabled}
                    disableFocusListener={!isCreateInvoiceDisabled}
                >
                    <Stack>
                        <TabBar
                            variant={TabBarVariants.SECONDARY_TABS}
                            onChange={handleTabChange}
                            initialValue={selectedTab}
                            items={[
                                {
                                    label: 'Create a new one',
                                    value: InvoiceTabType.CREATE_A_NEW_ONE,
                                    disabled: isCreateInvoiceDisabled,
                                },
                                { label: 'Attach existing', value: InvoiceTabType.ATTACH_EXISTING },
                            ]}
                            data-testid="tab-bar"
                        />
                    </Stack>
                </StyledTooltip>
            </TabsContainer>
            <Stack spacing={{ xs: 1.5, sm: 2.5 }}>
                <InvoiceModalContent billingRecord={billingRecord} />
                <Stack>
                    {selectedTab === InvoiceTabType.CREATE_A_NEW_ONE && (
                        <CreateNewInvoice
                            priceInCents={priceInCents}
                            oldPrice={initilBillingRecord.priceInCents}
                            onCreateInvoice={handleCreateInvoice}
                            handleSave={handleSave}
                        />
                    )}
                    {selectedTab === InvoiceTabType.ATTACH_EXISTING && (
                        <AttachExistingInvoice
                            invoiceData={invoiceData}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            setInvoiceData={setInvoiceData}
                            onSearchAgain={() => setInvoiceData(null)}
                            onAttachInvoice={handleAttachInvoice}
                        />
                    )}
                </Stack>
            </Stack>
        </StyledStack>
    );
};

export default AttachAnInvoiceModal;
