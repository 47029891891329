import React, { Dispatch, FC, SetStateAction } from 'react';
import ManagerItem from './ManagerItem';
import { useModalWithAnchor } from 'src/hooks/useModalWithAnchor';
import { Scope, useUserCan } from 'src/hooks/useUserCan';
import { PaginationData } from 'src/@types/pagination';
import { SubscriptionModel, SubscriptionUserModel } from 'src/@types/subscription-service-api';
import { MenuVariant } from 'src/components/Menu/Menu';
import { Menu } from 'src/components/Menu';
import { ManageActionType } from 'src/components/ManageActions/ManageActions';
import { actionModalKeyMap, TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { TableSection } from 'src/components/TableSection';
import type { GridColumnProps as BaseGridColumnProps } from 'src/components/TableSection/TableSection';

export type GridColumnProps = BaseGridColumnProps<'fullName' | 'jobTitle' | 'email'>;

type ManagerSectionType = {
    managers: SubscriptionUserModel[];
    actionsList?: Record<string, ManageActionType>;
    paginationModel: PaginationData;
    setPagination: Dispatch<SetStateAction<PaginationData>>;
    subscription: SubscriptionModel;
    updateManagersList?: () => void;
    isLoading: boolean;
    hasUserLink?: boolean;
    withRemoveButton?: boolean;
};

export const getGridColumnParams = (withRemoveButton?: boolean): GridColumnProps => ({
    fullName: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2,
        display: 'flex',
        alignItems: 'center',
        paddingRight: { xs: 5, md: 2 },
        flexWrap: 'nowrap',
        column_title: 'Name',
    },
    jobTitle: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        alignItems: 'center',
        paddingRight: { xs: 5, md: 2 },
        order: { xs: 2, md: 0 },
        column_title: 'Position',
    },
    email: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2,
        display: 'flex',
        alignItems: 'center',
        paddingRight: withRemoveButton ? 5 : 0,
        order: { xs: 1, md: 0 },
        column_title: 'Email address',
    },
});

const ManagerSection: FC<ManagerSectionType> = ({
    managers,
    actionsList = {},
    subscription,
    updateManagersList,
    paginationModel,
    setPagination,
    isLoading,
    hasUserLink,
    withRemoveButton,
}) => {
    const hasActions = Object.keys(actionsList).length > 0;
    const canManage = useUserCan(Scope.SUBSCRIPTIONS_SERVICE_WRITE);

    const modalPropsGetters = () => ({ extraProps: {
        subscriptionUUID: subscription.uuid,
        ...(managers && { subscriptionManagers: managers }),
    } });

    const allowedModalKeys = Object.entries(actionsList)
        .filter(([, { disabled }]) => !disabled)
        .map(([key]) => key);

    const { openModal } = useModalWithAnchor(allowedModalKeys, !isLoading, modalPropsGetters);

    const handleSelectChange = (selectedAction: string) => {
        const modalKey = actionModalKeyMap[selectedAction as keyof typeof actionModalKeyMap];
        openModal(modalKey);
    };

    const updateListOnItemRemoval = () => {
        if (paginationModel.currentPage > 1 && managers.length === 1) {
            return setPagination((prev) => ({
                ...prev,
                currentPage: prev.currentPage - 1,
            }));
        }

        return updateManagersList?.();
    };

    return (
        <TableSection
            testId="manager-section"
            paginationModel={paginationModel}
            setPagination={setPagination}
            isLoading={isLoading}
            headerPaddingBottom={1}
            items={managers}
            headerChildren={
                hasActions && canManage && (
                    <Menu
                        buttonVariant={MenuVariant.THREE_DOTS_TEXT_BUTTON}
                        tooltipMaxWidth="unset"
                        menuItems={Object.entries(actionsList)
                            .map(([value, { label, disabled, tooltipTitle }]) => (
                                { value, label, disabled, tooltipTitle }
                            ))}
                        onClick={handleSelectChange}
                    />
                )
            }
            gridColumnParams={getGridColumnParams(withRemoveButton)}
            sectionHeader="Managers"
            noContentMessage="There are no managers attached to this subscription"
            renderItem={(manager: SubscriptionUserModel, extraProps) => (
                <ManagerItem
                    gridColumnParams={extraProps.gridColumnParams}
                    manager={manager}
                    subscription={subscription}
                    showIcon={withRemoveButton}
                    hasUserLink={hasUserLink}
                    updateManagersList={updateListOnItemRemoval}
                />
            )}
        />
    );
};

export default ManagerSection;
