import React from 'react';
import WalletIcon, { WalletTextMap } from './WalletIcon';
import CardIcon, { CardTextMap } from './CardIcon';

type CreditCardProps = {
    shouldHideBrandIcon?: boolean;
    brand?: string | null;
    wallet?: string | null;
};

const CardType: React.FC<CreditCardProps> = ({ brand, wallet, shouldHideBrandIcon }) => {
    return (
        <>
            {wallet && <><WalletIcon wallet={wallet} /> {shouldHideBrandIcon && WalletTextMap[wallet]}</>}
            {shouldHideBrandIcon && ' |'}
            {brand && <><CardIcon brand={ shouldHideBrandIcon ? undefined : brand} /> {CardTextMap[brand]}</>}
        </>
    );
};

export default CardType;
