import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCheckoutConfig } from 'src/services/sso-api';
import { configSelector } from 'src/redux/slices';
import type { RootState } from 'src/redux/root-reducer';
import type { ConfigState } from 'src/@types/redux';

export const useInsiderRenewalPrice = () => {
    const [productPriceInCents, setProductPriceInCents] = useState<number>(0);
    const { renewalPriceInCents } = useSelector<RootState, ConfigState>(configSelector);

    useEffect(() => {
        getCheckoutConfig()
            .then(({ product }) => {
                setProductPriceInCents(product?.priceInCents ?? renewalPriceInCents);
            })
            .catch(() => {
                setProductPriceInCents(renewalPriceInCents);
            });
    }, []);

    return productPriceInCents;
};
