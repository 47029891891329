import { useElements, useStripe } from '@stripe/react-stripe-js';
import type { StripeCustomerInfo } from 'src/@types/sso-api';
import { isSameAddresses } from 'src/services/address';
import { saveCustomer } from 'src/services/sso-api';

export type UseSaveBillingAddressProps = {
    userUUID: string;
    customerInfo?: StripeCustomerInfo | null;
    onDataNotCompleteYet?: () => void | Promise<void>;
    onDataNotChanged?: () => void | Promise<void>;
    onDataSaved?: (customerInfo: StripeCustomerInfo) => void | Promise<void>;
    onDataSaveError?: (error: Error) => void | Promise<void>;
    setLoading?: (isLoading: boolean) => void;
};

export const useSaveBillingAddress = ({
    userUUID,
    customerInfo,
    onDataNotCompleteYet,
    onDataNotChanged,
    onDataSaved,
    onDataSaveError,
    setLoading,
}: UseSaveBillingAddressProps): () => Promise<StripeCustomerInfo | void> => {
    const stripe = useStripe();
    const elements = useElements();

    return async (): Promise<StripeCustomerInfo | void> => {
        if (!stripe || !elements) {
            return onDataNotCompleteYet?.();
        }

        const addressElement = elements.getElement('address');
        if (!addressElement) {
            return onDataNotCompleteYet?.();
        }

        setLoading?.(true);

        const { complete, value } = await addressElement.getValue();

        if (!complete || !value.address) {
            setLoading?.(false);
            return onDataNotCompleteYet?.();
        }

        const { address, name } = value;

        if (name === customerInfo?.name && isSameAddresses(customerInfo?.address, address)) {
            setLoading?.(false);
            return onDataNotChanged?.();
        }

        return saveCustomer({ userUUID, address, name })
            .then(async ({ customer }) => {
                await onDataSaved?.(customer);
                return customer;
            })
            .catch((error) => {
                if (!onDataSaveError) {
                    throw error;
                }

                return onDataSaveError(error);
            })
            .finally(() => setLoading?.(false));
    };
};
