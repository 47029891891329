import { ssoApiRequest } from './sso-api';
import type { PaginationParams } from 'src/@types/pagination';
import {
    AccessibleSubscription,
    AccessibleSubscriptionsProps,
    ActivationMinDateResponse,
    BillingRecordModel,
    BillingRecordRequest,
    BillingRecordsWithRenewalResponse,
    BillingRecordWithRenewal,
    CalculateDatesParams,
    CalculateDatesResponse,
    CalculatedRenewalItem,
    CalculatePriceRequest,
    CalculatePriceResponse,
    CustomPricingTermsModel,
    CustomPricingTermsProps,
    DomainInfoModel,
    DomainResponse,
    DomainsRequest,
    GetDomainInfoParams,
    GetManagersResponse,
    GetSubscriptionUsersResponse,
    InitialBillingRecordRequest,
    ManagersRequest,
    RenewalItem,
    SetOwnerRequest,
    SubscriberTypeByVertical,
    SubscriptionActionParams,
    SubscriptionActionsResponse,
    SubscriptionCreateParams,
    SubscriptionModel,
    SubscriptionRequest,
    SubscriptionSearchExpand,
    SubscriptionSearchParams,
    SubscriptionSearchProps,
    SubscriptionSearchResult,
    UpdateRenewalData,
    UpdateSubscriptionParams,
    UsersTypeByVerticalModel,
    UsersTypeByVerticalParams,
} from 'src/@types/subscription-service-api';
import type { APIClientRequestConfig } from 'src/@types/api-client';

export const ssApiRequest = async <
    RequestDataType extends object | void,
    ResponseType extends object | string | void,
>(
    url: string,
    config?: APIClientRequestConfig<RequestDataType>,
): Promise<ResponseType> => {
    return ssoApiRequest<RequestDataType, ResponseType>(`/ss/${url.replace(/^\//, '')}`, config);
};

export const addManager = async (subscriptionUUID: string, data: ManagersRequest): Promise<void> => {
    return ssApiRequest<ManagersRequest, void>(`/subscriptions/${subscriptionUUID}/managers`, {
        method: 'POST',
        data,
    });
};

export const getManagers = async (
    subscriptionUUID: string,
    params?: PaginationParams,
): Promise<GetManagersResponse> => {
    return ssApiRequest<PaginationParams, GetManagersResponse>(`/subscriptions/${subscriptionUUID}/managers`, {
        method: 'GET',
        params: {
            pageSize: params?.pageSize || 15,
            page: params?.page || 1,
        },
    });
};

export const removeManagers = async (subscriptionUUID: string, data: ManagersRequest): Promise<void> => {
    return ssApiRequest<ManagersRequest, void>(`/subscriptions/${subscriptionUUID}/managers`, {
        method: 'DELETE',
        data,
    });
};

export const setOwner = async (subscriptionUUID: string, data: SetOwnerRequest): Promise<void> => {
    return ssApiRequest<SetOwnerRequest, void>(`/subscriptions/${subscriptionUUID}/managers/set-owner`, {
        method: 'POST',
        data,
    });
};

export const calculateRenewalIndex = async (uuid: string) => {
    return ssApiRequest<void, CalculatedRenewalItem>(`/subscriptions/${uuid}/renewal-index/calculate`);
};

export const createUpcomingBillingRecord = (uuid: string) => {
    return ssApiRequest<void, Required<BillingRecordWithRenewal>>(`/subscriptions/${uuid}/billing/upcoming`, { method: 'POST' });
};

export const createInitialBillingRecord = async (
    uuid: string,
    data: Partial<InitialBillingRecordRequest>,
): Promise<BillingRecordWithRenewal> => {
    return ssApiRequest<Partial<InitialBillingRecordRequest>, BillingRecordWithRenewal>(`/subscriptions/${uuid}/billing/initial`, {
        method: 'POST',
        data,
    });
};

export const updateBillingRecord = async (uuid: string, billingRecordId: number, data: Partial<BillingRecordModel>) => {
    return ssApiRequest<Partial<BillingRecordModel>, BillingRecordModel>(`/subscriptions/${uuid}/billing/${billingRecordId}`, {
        method: 'PATCH',
        data,
    });
};

export const updateRenewal = async (
    { uuid, billingRecordId, renewalId }: { uuid: string, billingRecordId: number, renewalId: number },
    data: Partial<UpdateRenewalData>,
) => {
    return ssApiRequest<Partial<UpdateRenewalData>, RenewalItem>(`/subscriptions/${uuid}/billing/${billingRecordId}/renewal/${renewalId}`, {
        method: 'PATCH',
        data,
    });
};

export const geBillingRecord = async (uuid: string, params?: PaginationParams) => {
    return ssApiRequest<BillingRecordRequest, BillingRecordsWithRenewalResponse>(`/subscriptions/${uuid}/billing?expand[]=renewal`, {
        params: {
            pageSize: params?.pageSize,
            page: params?.page,
        },
    });
};

export const updateSubscription = async (
    uuid: string,
    data: Partial<UpdateSubscriptionParams>,
) => {
    return ssApiRequest<Partial<UpdateSubscriptionParams>, SubscriptionModel>(`/subscriptions/${uuid}`, {
        method: 'PATCH',
        data,
    });
};

export const subscriptionsSearch = async (data: SubscriptionSearchParams, expand?: SubscriptionSearchExpand[]) => {
    return ssApiRequest<SubscriptionSearchProps, SubscriptionSearchResult>('/subscriptions/search', {
        method: 'POST',
        data: {
            ...data,
            expand,
        },
    });
};

export const getSubscription = async (uuid: string) => {
    return ssApiRequest<SubscriptionRequest, SubscriptionModel>(`/subscriptions/${uuid}`, {
        method: 'GET',
        params: { expand: ['customAttributes'] },
    });
};

export const getAccessibleSubscriptions = async (
    userUUID: string,
    params: AccessibleSubscriptionsProps = { includeInactive: true, includeDraft: true },
): Promise<AccessibleSubscription[]> => {
    return ssApiRequest<AccessibleSubscriptionsProps, AccessibleSubscription[]>(`/users/${userUUID}/accessible-subscriptions`, {
        params,
    });
};

export const getUserTypeByVertical = async (
    userUUID: string,
) => {
    return ssApiRequest<void, SubscriberTypeByVertical[]>(`/users/${userUUID}/type-by-vertical`);
};

export const getUsersTypeByVertical = async (
    userUUIDs: string[],
) => {
    return ssApiRequest<UsersTypeByVerticalParams, UsersTypeByVerticalModel[]>('/users/type-by-vertical', {
        method: 'POST',
        data: {
            userUUIDs,
        },
    });
};

export const calculatePrice = async (data: CalculatePriceRequest): Promise<CalculatePriceResponse> => {
    return ssApiRequest<CalculatePriceRequest, CalculatePriceResponse>('/subscriptions/enterprise/calculate-price', {
        method: 'POST',
        data,
    });
};

export const getSubscriptionDomains = async (uuid: string, params?: PaginationParams) => {
    return ssApiRequest<PaginationParams, DomainResponse>(`/subscriptions/${uuid}/domains`, {
        params: {
            pageSize: params?.pageSize,
            page: params?.page,
        },
    });
};

export const getSubscriptionActions = async (
    uuid: string,
): Promise<SubscriptionActionsResponse> => {
    return ssApiRequest<void, SubscriptionActionsResponse>(`/subscriptions/${uuid}/actions`, {
        method: 'GET',
    });
};

export const getDomainInfo = async (data: GetDomainInfoParams) => {
    return ssApiRequest<GetDomainInfoParams, DomainInfoModel>('/domains/info', {
        method: 'POST',
        data,
    });
};

export const attachDomains = async (uuid: string, data: DomainsRequest): Promise<void> => {
    return ssApiRequest<DomainsRequest, void>(`/subscriptions/${uuid}/domains`, {
        method: 'POST',
        data,
    });

};

export const removeDomains = async (uuid: string, data: DomainsRequest): Promise<void> => {
    return ssApiRequest<DomainsRequest, void>(`/subscriptions/${uuid}/domains`, {
        method: 'DELETE',
        data,
    });
};

export const getSubscriptionUsers = async (uuid: string, params?: PaginationParams) => {
    return ssApiRequest<PaginationParams, GetSubscriptionUsersResponse>(`/subscriptions/${uuid}/users`, {
        params: {
            pageSize: params?.pageSize,
            page: params?.page,
        },
    });
};

export const cancelSubscription = async (subscriptionUUID: string): Promise<void> => {
    return ssApiRequest<void, void>(`/subscriptions/${subscriptionUUID}/cancel`, {
        method: 'POST',
    });
};

export const restoreSubscription = async (subscriptionUUID: string): Promise<void> => {
    return ssApiRequest<void, void>(`/subscriptions/${subscriptionUUID}/restore`, {
        method: 'POST',
    });
};

export const pauseSubscription = async (subscriptionUUID: string): Promise<void> => {
    return ssApiRequest<void, void>(`/subscriptions/${subscriptionUUID}/pause`, {
        method: 'POST',
    });
};

export const resumeSubscription = async (subscriptionUUID: string): Promise<void> => {
    return ssApiRequest<void, void>(`/subscriptions/${subscriptionUUID}/resume`, {
        method: 'POST',
    });
};

export const terminateSubscription = async (subscriptionUUID: string): Promise<void> => {
    return ssApiRequest<void, void>(`/subscriptions/${subscriptionUUID}/terminate`, {
        method: 'POST',
    });
};

export const reactivateSubscription = async (
    subscriptionUUID: string,
    data: Partial<SubscriptionModel>,
): Promise<SubscriptionModel> => {
    return ssApiRequest<Partial<SubscriptionModel>, SubscriptionModel>(`/subscriptions/${subscriptionUUID}/reactivate`, {
        method: 'POST',
        data,
    });
};

export const activateSubscription = async (
    subscriptionUUID: string,
    data?: SubscriptionActionParams,
): Promise<SubscriptionModel> => {
    return ssApiRequest<SubscriptionActionParams, SubscriptionModel>(`/subscriptions/${subscriptionUUID}/activate`, {
        method: 'POST',
        data,
    });
};

export const extendSubscription = async (
    subscriptionUUID: string,
    data: SubscriptionActionParams,
): Promise<SubscriptionModel> => {
    return ssApiRequest<SubscriptionActionParams, SubscriptionModel>(`/subscriptions/${subscriptionUUID}/extend`, {
        method: 'POST',
        data,
    });
};

export const extendExpirationDate = async (
    subscriptionUUID: string,
    data: SubscriptionActionParams,
): Promise<CalculateDatesResponse> => {
    return ssApiRequest<SubscriptionActionParams, CalculateDatesResponse>(`/subscriptions/${subscriptionUUID}/calculate-dates/extended-expiration-date`, {
        method: 'POST',
        data,
    });
};

export const createSubscription = async (data: SubscriptionCreateParams): Promise<SubscriptionModel> => {
    return ssApiRequest<SubscriptionCreateParams, SubscriptionModel>('/subscriptions', {
        method: 'POST',
        data,
    });
};

export const calculateDates = async (data: CalculateDatesParams) => {
    return ssApiRequest<CalculateDatesParams, CalculateDatesResponse>('/subscriptions/calculate-dates', {
        method: 'POST',
        data,
    });
};

export const fetchMinActivationDate = async (cycleLengthInMonths?: 1 | 12) => {
    return ssApiRequest<{ cycleLengthInMonths?: number }, ActivationMinDateResponse>('/subscriptions/calculate-dates/minimum-activation-date', {
        method: 'POST',
        data: {
            cycleLengthInMonths,
        },
    });
};

export const getCustomPricingTerms = async (subscriptionUUID: string) => {
    return ssApiRequest<void, CustomPricingTermsModel>(`/subscriptions/${subscriptionUUID}/custom-pricing-terms`);
};

export const createCustomPricingTerms = async (subscriptionUUID: string, data: CustomPricingTermsProps) => {
    return ssApiRequest<CustomPricingTermsProps, CustomPricingTermsModel>(`/subscriptions/${subscriptionUUID}/custom-pricing-terms`, {
        method: 'POST',
        data,
    });
};

export const updateCustomPricingTerms = async (subscriptionUUID: string, data: CustomPricingTermsProps) => {
    return ssApiRequest<CustomPricingTermsProps, CustomPricingTermsModel>(`/subscriptions/${subscriptionUUID}/custom-pricing-terms`, {
        method: 'PATCH',
        data,
    });
};

export const deleteCustomPricingTerms = async (subscriptionUUID: string) => {
    return ssApiRequest<void, CustomPricingTermsModel>(`/subscriptions/${subscriptionUUID}/custom-pricing-terms`, {
        method: 'DELETE',
    });
};
