import dayjs from 'dayjs';
import { AccessibleSubscription, SubscriptionModel } from 'src/@types/subscription-service-api';
import { ProfileAccessibleSubscription, SubscriberTypeModel } from 'src/@types/sso-api';
import {
    billingTypeRenderMapping,
    GENERAL_VERTICAL_ID,
    SubscriberType,
    SubscriptionBillingType,
    SubscriptionPaymentStatus,
    SubscriptionStatus,
    SubscriptionType,
} from 'src/constants';

const allowedStatuses = [SubscriptionStatus.ACTIVE, SubscriptionStatus.PAUSED];

export const getSubscriberType = (subscriberTypes: Array<SubscriberTypeModel>): SubscriberType => {
    return subscriberTypes
        .find(({ verticalId }) => verticalId === GENERAL_VERTICAL_ID)
        ?.type || SubscriberType.basic;
};

export const isOnGracePeriod = ({
    expirationDate,
    gracePeriodEndDate,
    status,
}: Pick<SubscriptionModel, 'expirationDate' | 'gracePeriodEndDate' | 'status'>): boolean => {
    if (!allowedStatuses.includes(status)) {
        return false;
    }

    const currentUTC = dayjs.utc();
    return currentUTC.isAfter(expirationDate) && currentUTC.isBefore(gracePeriodEndDate);
};

export const getSubscriptionPaymentStatus = (
    subscription: SubscriptionModel | AccessibleSubscription | ProfileAccessibleSubscription,
): SubscriptionPaymentStatus | null => {
    if (!allowedStatuses.includes(subscription.status)) {
        return null;
    }

    if (isOnGracePeriod(subscription)) {
        return SubscriptionPaymentStatus.ON_GRACE_PERIOD;
    }

    if (subscription.isCanceled) {
        return SubscriptionPaymentStatus.WILL_NOT_RENEW;
    }

    return null;
};

export const getPersonLabelForSubscription = (subscription: ProfileAccessibleSubscription): string => {
    const { isOwner, isManager, isUser } = subscription;
    const labels = isUser ? [] : ['Not a user'];

    if (isOwner) {
        labels.unshift('Owner');
    } else if (isManager) {
        labels.unshift('Manager');
    }

    return labels.join(' | ');
};

export const getBillingTypeLabel = (billingType: SubscriptionBillingType) => {
    return billingTypeRenderMapping[billingType];
};

export const shouldShowSection = (
    { billingType, type, status }: SubscriptionModel,
    sectionType: 'paymentMethod' | 'pricingTerms' | 'usage',
): boolean => {
    switch (sectionType) {
        case 'paymentMethod':
            return billingType === SubscriptionBillingType.AUTO_RENEWAL && type === SubscriptionType.INSIDER;
        case 'pricingTerms':
            return billingType === SubscriptionBillingType.INVOICED && type === SubscriptionType.INSIDER;
        case 'usage': {
            return allowedStatuses.includes(status) && type === SubscriptionType.ENTERPRISE;
        }
    }
};
