import { TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { GridColumnProps } from 'src/components/TableSection/TableSection';

export const gridColumnParams: GridColumnProps = {
    price: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Price',
        gap: { xs: 0, md: 1 },
    },
    discount: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Discount',
        gap: { xs: 0, md: 1 },
    },
    duration: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Duration',
        gap: { xs: 0, md: 1 },
    },
};
