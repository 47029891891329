import React from 'react';
import { StyledStack } from 'src/components/Modals/ChangePaymentMethodModal/ChangePaymentMethodModal.styles';
import { CardTextMap } from 'src/components/CreditCard/CardIcon';
import { PaymentMethodDetails } from 'src/@types/sso-api';
import CardType from 'src/components/CreditCard/CardType';
import Line from 'src/components/Line';
import { RadioGroupItem } from 'src/components/Radio/RadioGroup';

export const createRadioItem = (method: PaymentMethodDetails): RadioGroupItem => {
    const walletType = method.card?.walletType || null;
    const cardBrand = method.card?.brand;
    const lastFourDigits = method.card?.last4 ? `•••• ${method.card.last4}` : '';

    return {
        value: method.id,
        label: (
            <StyledStack direction="row" alignItems="center" gap={1}>
                <CardType
                    shouldHideBrandIcon={!!walletType}
                    brand={cardBrand}
                    wallet={walletType}
                />
                &nbsp;{lastFourDigits}
            </StyledStack>
        ),
        additionalNode: <Line />,
        additionalNodeProps: {
            direction: 'column',
            spacing: { xs: 2, md: 3 },
        },
    };
};

export const getRadioItems = (availablePaymentMethods: Array<PaymentMethodDetails>): Array<RadioGroupItem> => {
    return availablePaymentMethods.map(createRadioItem);
};

export const getPaymentMethodMessage = (method?: PaymentMethodDetails): string => {
    if (!method?.card) {
        return 'Unknown payment method';
    }
    return `${CardTextMap[method.card.brand]} •••• ${method.card.last4}`;
};

export const getSelectedMethod = (paymentMethods: Array<PaymentMethodDetails>, methodId: string) => {
    return paymentMethods.find((method) => method.id === methodId);
};
