import React, { ReactNode } from 'react';
import { FormControl, GridProps, RadioGroup as MuiRadioGroup, Stack, StackProps } from '@mui/material';
import { ContainerFormControlLabel, StyledFormControlLabel } from './Radio.styles';
import { Radio } from './index';

export type RadioGroupItem<T = string> = {
    label: ReactNode;
    value: T;
    additionalNode?: ReactNode;
    additionalNodeProps?: StackProps;
    disabled?: boolean;
};

type RadioGroupProps<T> = {
    items: Array<RadioGroupItem<T>>;
    onChange: (value: T) => void;
    selectedValue?: T | null;
    containerProps?: StackProps;
    groupDisabled?: boolean;
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
    fullWidthRadio?: boolean;
    spacing?: GridProps['spacing'];
};

const RadioGroup = <T = string>({
    items,
    onChange,
    selectedValue,
    containerProps,
    groupDisabled,
    labelPlacement,
    fullWidthRadio,
    spacing = 2,
}: RadioGroupProps<T>) => {
    return (
        <FormControl fullWidth>
            <MuiRadioGroup
                onChange={(event, value) => onChange(value as T)}
                value={selectedValue ?? ''}
            >
                <Stack spacing={spacing} {...containerProps}>
                    {items.map(({ value, label, additionalNode, additionalNodeProps, disabled }) => (
                        <ContainerFormControlLabel key={`${value}`} direction="row" {...additionalNodeProps}>
                            <StyledFormControlLabel
                                labelPlacement={labelPlacement}
                                disabled={groupDisabled || disabled}
                                data-testid={`${value}-radio`}
                                value={value}
                                control={<Radio />}
                                label={label}
                                data-full-width={fullWidthRadio}
                            />
                            {additionalNode}
                        </ContainerFormControlLabel>
                    ))}
                </Stack>
            </MuiRadioGroup>
        </FormControl>
    );
};

export default RadioGroup;
