import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { FormikHelpers } from 'formik';
import { SignedText } from './ContractDetailsModal.styles';
import { SwitchAdornment } from 'src/components/Inputs/Adornments/Switch';
import { INVALID_SALESFORCE_CONTRACT_LINK, SnackbarMessageVariants } from 'src/constants';
import { contractLinkValidator, validateSchemaObject } from 'src/services/validators';
import { LabeledInput } from 'src/components/Inputs';
import { useGeneralModal, useIsSubmitDisabled, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { Button } from 'src/components/Buttons';
import { FormWrapper } from 'src/components/Forms/components';
import { updateBillingRecord } from 'src/services/subscription-service-api';
import { BillingRecordModel, BillingRecordWithRenewal } from 'src/@types/subscription-service-api';

export type ContractDetailsModalProps = {
    billingRecord: BillingRecordWithRenewal;
    uuid: string;
};

const ContractDetailsModal: FC<ContractDetailsModalProps> = ({ billingRecord, uuid }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const initialValues: Partial<BillingRecordModel> = {
        contractLink: billingRecord.contractLink || '',
        contractSigned: billingRecord.contractSigned,
    };

    const { onFormChangeHandler, isSubmitDisabled, setIsSubmitDisabled } = useIsSubmitDisabled(initialValues);
    const { reloadPage } = useReloadPage();
    const { addMessage } = useSnackbarMessage();
    const { closeModal } = useGeneralModal();

    const isAdd = !billingRecord.contractLink && !billingRecord.contractSigned;

    const handleSaveContractDetails = async (
        values: Partial<BillingRecordModel>,
        helpers: FormikHelpers<Partial<BillingRecordModel>>,
    ) => {
        setIsSubmitting(true);
        setIsSubmitDisabled(true);

        const dataToUpdate: Partial<BillingRecordModel> = {
            contractSigned: values.contractSigned,
            contractLink: values.contractLink,
        };

        if (billingRecord.contractLink && !values.contractLink) {
            setIsSubmitting(false);

            helpers.setErrors({
                contractLink: INVALID_SALESFORCE_CONTRACT_LINK,
            });
            return;
        }

        return updateBillingRecord(uuid, billingRecord.id, dataToUpdate)
            .then(() => {
                closeModal();
                reloadPage();
            })
            .catch(() => {
                addMessage(`Failed to ${isAdd ? 'add' : 'update'} contract details`, SnackbarMessageVariants.WARNING);
                setIsSubmitDisabled(false);
            })
            .finally(() => setIsSubmitting(false));
    };

    return (
        <FormWrapper
            onFormChange={onFormChangeHandler}
            testId="contract-link-form"
            isSubmitting={isSubmitting}
            initialValues={initialValues}
            onSubmit={handleSaveContractDetails}
            validationSchema={validateSchemaObject({ contractLink: contractLinkValidator })}
        >
            <Stack position="relative">
                <SignedText>Signed</SignedText>
                <LabeledInput
                    disallowSpace
                    flexGrow={1}
                    placeholder="Add contract link"
                    name="contractLink"
                    label="Contract link"
                    testId="contract-link-input"
                    endAdornment={<SwitchAdornment />}
                />
                <Stack direction="row" marginTop={{ xs: 3, md: 6 }} spacing={{ xs: 2, sm: 2.5 }}>
                    <Button onClick={closeModal} minSize="small" variant="secondary" fullWidth>Cancel</Button>
                    <Button disabled={isSubmitting || isSubmitDisabled} minSize="small" fullWidth type="submit">
                        {isAdd ? 'Add' : 'Update'}
                    </Button>
                </Stack>
            </Stack>
        </FormWrapper>
    );
};

export default ContractDetailsModal;
