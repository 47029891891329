import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useGeneralModal } from './useGeneralModal';
import { GeneralModalKey } from 'src/constants';
import { OpenModalActionPayload } from 'src/@types/redux';

export const useModalWithAnchor = (
    allowedModalKeys: string[],
    isLoaded: boolean,
    extraPropsGetter?: (selectedAction: string) => Partial<OpenModalActionPayload>,
) => {
    const [opened, setOpened] = useState<boolean>(false);

    const location = useLocation();
    const navigate = useNavigate();
    const anchorKey = location.hash.slice(1) as GeneralModalKey;

    const { openModal } = useGeneralModal();

    useEffect(() => {
        if (isLoaded && allowedModalKeys.includes(anchorKey) && !opened) {
            openModal({ key: anchorKey, ...extraPropsGetter?.(anchorKey) });
            setOpened(true);
        }
    }, [isLoaded, allowedModalKeys]);

    return {
        openModal: (key: GeneralModalKey, hash: string = key) => {
            navigate({ ...location, hash });
            openModal({ ...extraPropsGetter?.(hash), key });
        },
    };
};
