import { useEffect, useState } from 'react';
import { useSnackbarMessage } from './useSnackbarMessage';
import { Scope, useUserCan } from './useUserCan';
import { SnackbarMessageVariants, UserRoles } from 'src/constants';
import { searchCredentials } from 'src/services/sso-api';

export const useCanEditUser = (uuid: string) => {
    const [canEdit, setCanEdit] = useState<boolean>(false);

    const canModifyUser = useUserCan(Scope.CREDENTIALS_MODIFY_USER);
    const canModifyAll = useUserCan(Scope.CREDENTIALS_MODIFY_ALL);

    const { addMessage } = useSnackbarMessage();

    useEffect(() => {
        if (!canModifyUser) {
            setCanEdit(false);
            return;
        }

        if (canModifyAll) {
            setCanEdit(true);
            return;
        }

        searchCredentials({ fields: { uuid } })
            .then((userRoleResponse) => {
                const userCredentials = userRoleResponse.data[0];
                setCanEdit(!userCredentials || userCredentials.role === UserRoles.USER);
            })
            .catch(() => {
                addMessage(
                    'Failed to identify the role. Please, reload the page.',
                    SnackbarMessageVariants.ERROR,
                );
                setCanEdit(false);
            });
    }, [uuid, canModifyUser, canModifyAll]);

    return canEdit;
};
