import React, { FC } from 'react';
import { Stack } from '@mui/material';
import type { BillingRecordModel } from 'src/@types/subscription-service-api';
import { PriceRow } from 'src/components/PriceRow';
import { Button } from 'src/components/Buttons';

type CreateNewInvoiceProps = {
    priceInCents: number | null;
    oldPrice: number | null;
    onCreateInvoice: () => void;
    handleSave: (renewal: Partial<BillingRecordModel>) => void;
};

const CreateNewInvoice: FC<CreateNewInvoiceProps> = ({ priceInCents, oldPrice, onCreateInvoice, handleSave }) => (
    <>
        <PriceRow
            skipLine
            paddingTop={{ xs: 0 }}
            title="Price:"
            height={{ xs: 'unset', sm: '20px' }}
            paddingBottom={{ xs: 0, sm: 2.5 }}
            handleSave={handleSave}
            finalPrice={priceInCents}
            oldPrice={oldPrice}
        />
        <Stack paddingTop={3}>
            <Button data-testid="create-invoice-btn" onClick={onCreateInvoice} fullWidth>
            Create invoice
            </Button>
        </Stack>
    </>
);

export default CreateNewInvoice;
