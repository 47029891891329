import React, { Fragment } from 'react';
import { Box } from '@mui/material';
import { Link } from 'src/components/Link';
import Tooltip from 'src/components/Tooltip';
import {
    BillingCycleLengthInMonth,
    billingTypeRenderMapping,
    SubscriptionBillingType,
    SubscriptionType,
} from 'src/constants';
import type { RadioGroupItem } from 'src/components/Radio/RadioGroup';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';
import { ReactComponent as StripeLinkIcon } from 'src/assets/icons/stripe-link-icon.svg';

export const billingCycleRadioItems = [
    {
        value: BillingCycleLengthInMonth.YEAR,
        label: '1 year',
    },
    {
        value: BillingCycleLengthInMonth.MONTH,
        label: '1 month',
    },
];

export const getBillingRadioItems = (
    selectedSubscriptionType: SubscriptionType,
    featureAccountUpgrade: boolean,
): Array<RadioGroupItem<SubscriptionBillingType>> => {
    const typesToRender: Array<RadioGroupItem<SubscriptionBillingType>> = [
        {
            value: SubscriptionBillingType.FREE,
            label: billingTypeRenderMapping[SubscriptionBillingType.FREE],
        },
        {
            value: SubscriptionBillingType.INVOICED,
            label: billingTypeRenderMapping[SubscriptionBillingType.INVOICED],
        },
    ];

    if (selectedSubscriptionType === SubscriptionType.INSIDER) {
        typesToRender.push(
            {
                value: SubscriptionBillingType.AUTO_RENEWAL,
                label: billingTypeRenderMapping[SubscriptionBillingType.AUTO_RENEWAL],
                disabled: true,
                additionalNode: featureAccountUpgrade && (
                    <Fragment>
                        <Tooltip arrow title="Upgrade">
                            <Box>
                                <Link
                                    display="flex"
                                    alignItems="center"
                                    data-testid="stripe-link-icon"
                                    href="/upgrade/insider"
                                    target="_blank"
                                >
                                    <StripeLinkIcon width="16px" height="16px" />
                                </Link>
                            </Box>
                        </Tooltip>
                        <Tooltip arrow title="To create a Stripe-managed subscription, client has to go through the Insider flow">
                            <InfoIcon />
                        </Tooltip>
                    </Fragment>
                ),
                additionalNodeProps: {
                    spacing: 1,
                    alignItems: 'center',
                },
            },
        );
    }

    return typesToRender;
};

export const getNumberOfCyclesWhenBillingTypeChange = (
    subscriptionBillingType?: SubscriptionBillingType,
    numberOfCycles?: number,
) => {
    if (subscriptionBillingType === SubscriptionBillingType.INVOICED || !numberOfCycles) {
        return 1;
    }

    return numberOfCycles;
};
