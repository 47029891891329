import type { StripeAddress } from 'src/@types/sso-api';

const isSameAddressField = (address1: StripeAddress, address2: StripeAddress, field: keyof StripeAddress): boolean => {
    return (!address1[field] && !address2[field]) || address1[field] === address2[field];
};

export const isSameAddresses = (
    address1: StripeAddress | null | undefined,
    address2: StripeAddress | null | undefined,
): boolean => {
    if (!address1 || !address2) {
        return !address1 === !address2;
    }

    return isSameAddressField(address1, address2, 'city')
        && isSameAddressField(address1, address2, 'line1')
        && isSameAddressField(address1, address2, 'line2')
        && isSameAddressField(address1, address2, 'state')
        && isSameAddressField(address1, address2, 'postal_code')
        && isSameAddressField(address1, address2, 'country');
};
