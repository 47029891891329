import React from 'react';
import { gridColumnParams } from './schemas';
import { FinalPriceRowValue } from 'src/components/FinalPriceRow';
import { BillingCycleLengthInMonth } from 'src/constants';
import { CustomPricingTermsModel } from 'src/@types/subscription-service-api';
import { formatDiscount, formatDuration, formatPrice } from 'src/services/formatters';

export const getPricingTermsFields = (
    pricingTerms: CustomPricingTermsModel,
    productPriceInCents: number,
    cycleLengthInMonths: BillingCycleLengthInMonth,
) => {
    if (pricingTerms.fixedPriceInCents) {
        return [
            {
                title: gridColumnParams.price.column_title,
                value: formatPrice(pricingTerms.fixedPriceInCents),
                gridProps: gridColumnParams.price,
            },
            {
                title: gridColumnParams.duration.column_title,
                value: formatDuration(pricingTerms.discountDurationInCycles, cycleLengthInMonths),
                gridProps: gridColumnParams.duration,
            },
        ];
    }

    return [
        {
            title: gridColumnParams.price.column_title,
            value: <FinalPriceRowValue
                priceInCents={productPriceInCents}
                discountValue={pricingTerms.discountValue}
                discountType={pricingTerms.discountType}
                direction="row-reverse"
                priceFontSize="14px"
                spacing={1}
                isNormal
            />,
            gridProps: gridColumnParams.price,
        },
        {
            title: gridColumnParams.discount.column_title,
            value: formatDiscount(pricingTerms.discountValue, pricingTerms.discountType),
            gridProps: gridColumnParams.discount,
        },
        {
            title: gridColumnParams.duration.column_title,
            value: formatDuration(pricingTerms.discountDurationInCycles, cycleLengthInMonths),
            gridProps: gridColumnParams.duration,
        },
    ];
};
