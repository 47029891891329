import { Box, BoxProps, Stack } from '@mui/material';
import React, { FC, useMemo } from 'react';
import type { NewsLetterSetSchema } from 'src/@types/newsletters-set';
import StyledTooltip from 'src/components/Tooltip';
import { NewslettersSet } from 'src/components/NewslettersSet';
import { ProfileEmailDeliveryPreferences } from 'src/@types/sso-api';
import { ChangeAllLabeledCheckbox } from 'src/components/Checkbox';
import { Button } from 'src/components/Buttons';
import { NewslettersDeliveryContainer } from 'src/components/NewslettersDelivery/NewslettersDelivery.styles';
import { FormWrapper } from 'src/components/Forms/components';
import { useIsSubmitDisabled } from 'src/hooks';
import { UDBNewsletterChannel } from 'src/constants';
import { getFormattedValues } from 'src/services/newsletter-formatters';
import Line from 'src/components/Line';

type VerticalsProps = {
    preferences?: ProfileEmailDeliveryPreferences;
    newsLetterSetSchema: NewsLetterSetSchema;
    onCheckboxChange?: (checked: boolean, name: string) => void;
    onSubmit?: (values: TransformedValues) => Promise<void>;
    marginTop?: { xs?: number, sm?: number, md?: number } | number;
    paddingTop?: { xs?: number, sm?: number, md?: number } | number;
    headerSectionDisplay?: BoxProps['display'];
    isSubmitting?: boolean;
    isEmailDisabled?: boolean;
    isSMSDisabled?: boolean;
    isEditable?: boolean;
    isLoaded?: boolean;
};

export type VerticalsFormValues = Record<string, boolean>;
export type TransformedValues = Record<string, UDBNewsletterChannel[]>;

const NewslettersDelivery: FC<VerticalsProps> = ({
    isEditable,
    onCheckboxChange,
    newsLetterSetSchema,
    paddingTop,
    marginTop,
    onSubmit,
    isSubmitting = false,
    isLoaded,
    isEmailDisabled,
    isSMSDisabled,
    headerSectionDisplay,
    preferences = {
        verticals: [],
        email: '',
        uuid: '',
    },
}) => {
    const { initialValues, userNewsletters } = useMemo(() => {
        const selectedNewsletters: Record<UDBNewsletterChannel, number[]> = {
            [UDBNewsletterChannel.EMAIL]: [],
            [UDBNewsletterChannel.SMS]: [],
        };

        const values = preferences.verticals
            .reduce<VerticalsFormValues>((preparedSate, { newsletters }) => {
            newsletters.forEach(({ selectedChannels, id }) => {
                const hasEmail = selectedChannels.includes(UDBNewsletterChannel.EMAIL);
                const hasSMS = selectedChannels.includes(UDBNewsletterChannel.SMS);

                if (hasEmail) {
                    selectedNewsletters[UDBNewsletterChannel.EMAIL].push(id);
                }

                if (hasSMS) {
                    selectedNewsletters[UDBNewsletterChannel.SMS].push(id);
                }

                preparedSate[`${id}_${UDBNewsletterChannel.EMAIL}`] = hasEmail;
                preparedSate[`${id}_${UDBNewsletterChannel.SMS}`] = hasSMS;
            });

            return preparedSate;
        }, { unsubscribeAll: false });

        return { initialValues: values, userNewsletters: selectedNewsletters };
    }, [preferences.verticals]);

    const { isSubmitDisabled, onFormChangeHandler, setIsSubmitDisabled } =
        useIsSubmitDisabled<VerticalsFormValues>(initialValues);

    const handleSubmit = (values: VerticalsFormValues) => {
        if (!onSubmit) {
            return;
        }

        setIsSubmitDisabled(true);
        onSubmit(getFormattedValues(values))
            .catch(() => setIsSubmitDisabled(false));
    };

    return (
        <NewslettersDeliveryContainer marginTop={marginTop} paddingTop={paddingTop}>
            <FormWrapper
                testId="verticals-form"
                onFormChange={onFormChangeHandler}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                enableReinitialize
            >
                {preferences?.verticals.map((vertical) => (
                    <Box key={vertical.id}>
                        <NewslettersSet
                            isLoaded={isLoaded}
                            headerSectionDisplay={headerSectionDisplay}
                            userUUID={preferences.uuid}
                            isEditable={isEditable}
                            vertical={vertical}
                            onCheckboxChange={onCheckboxChange}
                            newsLetterSetSchema={newsLetterSetSchema}
                            onFormChangeHandler={onFormChangeHandler}
                            isEmailDisabled={isEmailDisabled}
                            isSMSDisabled={isSMSDisabled}
                            userNewsletters={userNewsletters}
                            hasPhoneNumber={!!preferences.phoneNumber}
                        />
                        <Line />
                    </Box>
                ))}
                {onSubmit && (
                    <Stack
                        paddingX={{ xs: 2, md: 4.5 }}
                        paddingY={{ xs: 3, md: 4.5 }}
                        direction={{ xs: 'column', md: 'row' }}
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Stack
                            alignItems="center"
                            paddingRight={0.5}
                            direction={{ xs: 'row-reverse', md: 'row' }}
                            justifyContent={{ xs: 'space-between', md: 'flex-start' }}
                        >
                            <ChangeAllLabeledCheckbox
                                unCheckAll
                                shouldCacheValues
                                name="unsubscribeAll"
                                label="Unsubscribe from all"
                                testId="unsubscribe-all"
                                onFormChangeHandler={onFormChangeHandler}
                            />
                        </Stack>
                        <StyledTooltip title={isSubmitDisabled ? 'No changes were made' : ''} arrow>
                            <Box width={{ xs: '100%', md: 260 }}>
                                <Button type="submit" testId="submit-button" fullWidth disabled={isSubmitDisabled}>
                                    Confirm
                                </Button>
                            </Box>
                        </StyledTooltip>
                    </Stack>
                )}
            </FormWrapper>
        </NewslettersDeliveryContainer>
    );
};

export default NewslettersDelivery;
