import styled from 'styled-components';
import { Box } from '@mui/material';

export const InfoBannerContainer = styled(Box)`
    && {
        align-items: center;
        display: flex;
        background-color: ${({ theme }) => theme.palette.snackbar.infoBackground};
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 15px;
        border-radius: 4px;
    }
`;
