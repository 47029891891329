import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { OwnerLablel, SearchTitle } from './AddManagerForm.styles';
import Checkbox from 'src/components/Checkbox/Checkbox';
import type { User } from 'src/@types/unified-db-api';
import type { SubscriptionUserModel } from 'src/@types/subscription-service-api';
import { useReloadPage } from 'src/hooks/useReloadPage';
import {
    DEFAULT_MODAL_MAX_WIDTH,
    DEFAULT_MODAL_PADDING_BOTTOM,
    DEFAULT_MODAL_PADDING_X,
} from 'src/components/Modals/BaseModal';
import { addManager, setOwner } from 'src/services/subscription-service-api';
import { useGeneralModal, useSnackbarMessage } from 'src/hooks';
import { UserInfoBox } from 'src/components/UserInfoBox';
import { Button } from 'src/components/Buttons';
import { SnackbarMessageVariants } from 'src/constants';

type AddManagerFormProps = {
    data: User;
    subscriptionUUID: string;
    subscriptionManagers: SubscriptionUserModel[] | Omit<User, 'jobInfo'>[];
    onSuccess?: (user: User, isOwner: boolean) => void;
    setIsLoading: (isLoading: boolean) => void;
};

const AddManagerForm: FC<AddManagerFormProps> = ({
    data,
    subscriptionUUID,
    subscriptionManagers,
    onSuccess,
    setIsLoading,
}) => {
    const [isOwner, setIsOwner] = useState<boolean>(false);
    const [isManager, setIsManager] = useState<boolean>(false);

    const { addMessage } = useSnackbarMessage();
    const { closeModal } = useGeneralModal();
    const { reloadPage } = useReloadPage();

    useEffect(() => {
        setIsOwner(false);
        setIsManager(
            subscriptionManagers.some((manager) => (
                // this need to be refactored so managers be always the same type
                'uuid' in manager ? manager.uuid === data.uuid : manager.userUUID === data.uuid
            )),
        );
    }, [data]);

    const onSubmit = () => {
        setIsLoading(true);

        const { uuid, fullName } = data;

        addManager(subscriptionUUID, { managers: [uuid] })
            .then(async () => {
                addMessage(
                    `${fullName} has been successfully added as a manager`,
                    SnackbarMessageVariants.SUCCESS,
                );

                if (isOwner) {
                    await setOwner(subscriptionUUID, { userUUID: uuid })
                        .then(() => {
                            addMessage(
                                `${fullName} has been successfully set as an owner`,
                                SnackbarMessageVariants.SUCCESS,
                            );
                        })
                        .catch(() => {
                            addMessage(`Failed to set ${fullName} as an owner`, SnackbarMessageVariants.ERROR);
                        });
                }
            })
            .then(() => {
                if (onSuccess) {
                    onSuccess(data, isOwner);
                } else {
                    reloadPage();
                    closeModal();
                }
            })
            .catch(() => {
                addMessage(`Failed to set ${fullName} as a manager`, SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsOwner(event.target.checked);
    };

    return (
        <Box paddingTop={3}>
            <SearchTitle
                marginBottom={{ xs: 1, sm: 2 }}
                paddingX={DEFAULT_MODAL_PADDING_X}
            >
                Search results
            </SearchTitle>
            <Box
                data-testid="add-manager-form"
                paddingX={DEFAULT_MODAL_PADDING_X}
                paddingBottom={DEFAULT_MODAL_PADDING_BOTTOM}
                maxWidth={DEFAULT_MODAL_MAX_WIDTH}
            >
                <UserInfoBox
                    data={data}
                    isManager={isManager}
                />
                {!isManager && (
                    <>
                        <Box
                            data-testid="add-owner-block"
                            marginY={3}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                            >
                                <Checkbox
                                    data-testid="isOwner"
                                    name="isOwner"
                                    value={isOwner}
                                    onChange={onCheckboxChange}
                                    checked={isOwner}
                                />
                                <OwnerLablel marginLeft={{ xs: 1, sm: 1.25 }}>
                                            Also set as owner
                                </OwnerLablel>
                            </Stack>
                        </Box>
                        <Button
                            data-testid="add-manager-submit-button"
                            type="submit"
                            fullWidth
                            onClick={onSubmit}
                        >
                            {isOwner ? 'Add as an owner' : 'Add as a manager' }
                        </Button>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default AddManagerForm;
