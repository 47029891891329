import styled from 'styled-components';
import { Box } from '@mui/material';

export const StyledGracePeriod = styled(Box)`
    && {
        align-items: center;
        padding: 14px 16px;
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.palette.dirtyWhite};
        color: ${({ theme }) => theme.palette.grey};
        background: #F4F4F4;  
        height: 21px;
        font-family: ${({ theme }) => theme.fonts.normal};
      
        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
          height: 14px;
        }
    } 
`;
