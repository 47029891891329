import React, { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import { AddButton, StyledText } from './ChangePaymentMethodModal.styles';
import { getPaymentMethodMessage, getRadioItems, getSelectedMethod } from './services/getters';
import ManagePaymentMethodModal from './components/ManagePaymentModal';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { BaseModal } from 'src/components/Modals/BaseModal';
import { Button } from 'src/components/Buttons';
import { getAvailableSubscriptionPaymentMethods, updateProfileSubscriptionPaymentMethod } from 'src/services/sso-api';
import { PaymentMethodDetails } from 'src/@types/sso-api';
import RadioGroup from 'src/components/Radio/RadioGroup';
import { SnackbarMessageVariants } from 'src/constants';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import { ConfirmMessage } from 'src/components/Modals/AddDomainModal/AddDomainModal.styles';

export type ChangePaymentMethodModalProps = {
    id: string;
};

const ChangePaymentMethodModal: FC<ChangePaymentMethodModalProps> = ({ id }) => {
    const [availablePaymentMethods, setAvailablePaymentMethods] = useState<Array<PaymentMethodDetails>>([]);
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string>(id);
    const [shouldConfirm, setShouldConfirm] = useState<boolean>(false);
    const [showManagePaymentModal, setShowManagePaymentModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { uuid = '' } = useParams<{ uuid: string }>();
    const { addMessage } = useSnackbarMessage();
    const { closeModal } = useGeneralModal();
    const { reloadPage } = useReloadPage();

    const fetchAvailableMethods = () => {
        setIsLoading(true);
        getAvailableSubscriptionPaymentMethods(uuid)
            .then((response) => {
                setAvailablePaymentMethods(response.data);
            })
            .catch(() => {
                addMessage('Failed to get payment methods', SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchAvailableMethods();
    }, []);

    const selectedMethod = getSelectedMethod(availablePaymentMethods, selectedPaymentMethodId);

    const confirmChanges = async () => {
        return updateProfileSubscriptionPaymentMethod(uuid, selectedPaymentMethodId)
            .then(() => {
                reloadPage();
                closeModal();
                addMessage(`${getPaymentMethodMessage(selectedMethod)} successfully set as default`, SnackbarMessageVariants.SUCCESS);
            })
            .catch(() => {
                setShouldConfirm(false);
                addMessage(`Failed to set ${getPaymentMethodMessage(selectedMethod)} as default`, SnackbarMessageVariants.ERROR);
            });
    };

    if (showManagePaymentModal) {
        return (
            <BaseModal
                step={1}
                dataTestId="manage-payment-modal"
                title="Change payment method"
                open={showManagePaymentModal}
                handleClose={closeModal}
                handleGoBack={() => setShowManagePaymentModal(false)}
            >
                <ManagePaymentMethodModal
                    uuid={uuid}
                    defaultMethodId={id}
                    refreshMethods={fetchAvailableMethods}
                    handleGoBack={setShowManagePaymentModal}
                    paymentMethods={availablePaymentMethods}
                />
            </BaseModal>
        );
    }

    if (shouldConfirm) {
        return (
            <BaseModal
                open
                step={1}
                overlayScope="relative"
                handleClose={closeModal}
                handleGoBack={() => setShouldConfirm(false)}
                title="Change payment method"
            >
                <ConfirmationModal
                    modalId="confirm-modal"
                    onConfirm={confirmChanges}
                    onCancel={() => setShouldConfirm(false)}
                    subtitle={(
                        <ConfirmMessage>
                            Are you sure you want to set&nbsp;
                            <b>{getPaymentMethodMessage(selectedMethod)}</b>
                            &nbsp;as default payment method for this subscription?
                        </ConfirmMessage>
                    )}
                />
            </BaseModal>
        );
    }

    return (
        <Stack gap={{ xs: 2, md: 3 }} data-testid="change-payment-modal">
            {isLoading && <LoaderOverlay />}
            <Stack direction="row" justifyContent="space-between">
                <StyledText>Payment methods</StyledText>
                <AddButton onClick={() => setShowManagePaymentModal(true)}>Manage</AddButton>
            </Stack>
            <RadioGroup
                fullWidthRadio
                spacing={{ xs: 2, md: 3 }}
                labelPlacement="start"
                items={getRadioItems(availablePaymentMethods)}
                selectedValue={selectedPaymentMethodId}
                onChange={setSelectedPaymentMethodId}
            />
            <AddButton data-testid="add-button">
                <AddIcon />
                Add Payment Method
            </AddButton>
            <Stack marginTop={{ xs: 1, md: 0 }}>
                <Button
                    disabled={selectedPaymentMethodId === id}
                    fullWidth
                    data-testid="update-payment-button"
                    onClick={() => setShouldConfirm(true)}
                >
                Update payment method
                </Button>
            </Stack>
        </Stack>
    );
};

export default ChangePaymentMethodModal;
