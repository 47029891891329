import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { filteringSchema } from './schemas/filtering-schema';
import { credentialsDataGridColumns } from './schemas/credentials-table-schema';
import { getModelFromQuery, makeUrlSearchParams } from 'src/services/query-helpers';
import { ToolBarModelType } from 'src/@types/tool-bar';
import {
    DATA_GRID_NO_ROWS_MESSAGE,
    FAILED_TO_LOAD_DATA_GRID_MESSAGE,
    GRID_DEFAULT_PAGE_SIZE,
    GRID_DEFAULT_PAGE_SIZE_OPTIONS,
    PAGE_TITLES,
    PAGINATION_DEFAULT_PAGE,
} from 'src/constants';
import { searchCredentials } from 'src/services/sso-api';
import { BasicLayout, PageVariant } from 'src/layouts';
import { ToolBar } from 'src/components/ToolBar';
import { ToolBarItem } from 'src/components/ToolBar/components/ToolBarItem';
import { DataGrid } from 'src/components/DataGrid';
import { ReactComponent as SortingIcon } from 'src/assets/icons/sorting-icon.svg';
import { ReactComponent as FiltersIcon } from 'src/assets/icons/filters-icon.svg';
import type { Credentials } from 'src/@types/credentials';
import { getValidModel } from 'src/components/ToolBar/services/tool-bar-validators';
import { getSortingApiParams, getSortingSchema } from 'src/services/sorting-helpers';
import { getFiltersSsoApiParams } from 'src/services/filtering-helpers';
import PageTitle from 'src/components/PageTitle';

const CredentialsPage: FC = () => {
    const [noDataMessage, setNoDataMessage] = useState<string>(DATA_GRID_NO_ROWS_MESSAGE);
    const [users, setUsers] = useState<Credentials[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchParams] = useSearchParams();

    const location = useLocation();
    const navigate = useNavigate();

    const page = Math.max(parseInt(`${searchParams.get('page')}`) || PAGINATION_DEFAULT_PAGE, 0);
    const pageSize = parseInt(searchParams.get('pageSize') ?? '0');

    const sortingSchema = getSortingSchema(credentialsDataGridColumns);
    const validFiltersModel = getValidModel(getModelFromQuery('filters', searchParams), filteringSchema);
    const validSortingModel = getValidModel(getModelFromQuery('sorting', searchParams), sortingSchema);

    const [filtersModel, setFiltersModel] = useState<ToolBarModelType>(validFiltersModel);
    const [sortingModel, setSortingModel] = useState<ToolBarModelType>(validSortingModel);

    const [pageState, setPageState] = useState<number>(page);
    const [pageSizeState, setPageSizeState] =
        useState<number>(GRID_DEFAULT_PAGE_SIZE_OPTIONS.includes(pageSize) ? pageSize : GRID_DEFAULT_PAGE_SIZE);

    useEffect(() => {
        setFiltersModel(validFiltersModel);
    }, [JSON.stringify(validFiltersModel)]);

    useEffect(() => {
        setSortingModel(validSortingModel);
    }, [JSON.stringify(validSortingModel)]);

    useEffect(() => {
        setIsLoading(true);

        navigate({ ...location, search: makeUrlSearchParams({
            ...(Object.values(sortingModel).length && { sorting: encodeURI(JSON.stringify(sortingModel)) }),
            ...(Object.values(filtersModel).length && { filters: encodeURI(JSON.stringify(filtersModel)) }),
            ...(pageState !== PAGINATION_DEFAULT_PAGE && { page: pageState }),
            ...(pageSizeState !== GRID_DEFAULT_PAGE_SIZE && { pageSize: pageSizeState }),
        }).toString() });

        searchCredentials({
            page: pageState,
            pageSize: pageSizeState,
            ...getSortingApiParams(sortingModel),
            ...getFiltersSsoApiParams(filtersModel),
        }).then((response) => {
            setUsers(response.data);
            setTotalCount(response.totalCount);
            setNoDataMessage(DATA_GRID_NO_ROWS_MESSAGE);
        }).catch(() => {
            setUsers([]);
            setTotalCount(0);
            setNoDataMessage(FAILED_TO_LOAD_DATA_GRID_MESSAGE);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [JSON.stringify(filtersModel), JSON.stringify(sortingModel), pageSizeState, pageState]);

    return (
        <BasicLayout pageVariant={PageVariant.DATA_GRID} testId="credentials-page">
            <Stack
                data-testid="credentials-toolbar-panel"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                marginBottom={{ xs: 0, md: 0.5 }}
            >
                <PageTitle title={PAGE_TITLES.CREDENTIALS} />
                <ToolBar>
                    <ToolBarItem
                        toolBarTitle="Filter conditions"
                        schema={filteringSchema}
                        model={filtersModel}
                        setModel={(model) => {
                            setFiltersModel(model);
                            setPageState(PAGINATION_DEFAULT_PAGE);
                        }}
                        submitLabel="Apply filter"
                        clearLabel="Clear filter"
                        selectPlaceholder="Filter by"
                        toolBarButton={{
                            icon: <FiltersIcon />,
                            label: 'Filters',
                        }}
                    />
                    <ToolBarItem
                        toolBarTitle="Select sorting type"
                        schema={sortingSchema}
                        model={sortingModel}
                        setModel={(model) => {
                            setSortingModel(model);
                            setPageState(PAGINATION_DEFAULT_PAGE);
                        }}
                        submitLabel="Sort"
                        clearLabel="Clear"
                        selectPlaceholder="Sort by"
                        toolBarButton={{
                            icon: <SortingIcon />,
                            label: 'Sorting',
                        }}
                    />
                </ToolBar>
            </Stack>
            <DataGrid
                noRowsOverlayMessage={noDataMessage}
                getRowId={(row) => row.id}
                rows={users}
                columns={credentialsDataGridColumns.map((column) => ({
                    ...column,
                    sortable: false,
                }))}
                rowCount={totalCount}
                pageSize={pageSizeState}
                pageState={pageState}
                setPageState={setPageState}
                setPageSizeState={setPageSizeState}
                loading={isLoading}
                checkboxSelection
            />
        </BasicLayout>
    );
};

export default CredentialsPage;
