import styled from 'styled-components';
import { Box } from '@mui/material';

export const FramedBox = styled(Box)`
    line-height: normal;
    font-style: normal;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.palette.borderGrey};
    background: ${({ theme }) => theme.palette.white};
`;
