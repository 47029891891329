import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { subscriptionsSearch } from 'src/services/subscription-service-api';
import PageTitle from 'src/components/PageTitle';
import { getSubscriptionDataGridColumns } from 'src/pages/udb/Subscriptions/schemas/subscription-table-schema';
import { DataGrid } from 'src/components/DataGrid';
import { BasicLayout, PageVariant } from 'src/layouts';
import { TabBar } from 'src/components/TabBar';
import {
    DATA_GRID_NO_ROWS_MESSAGE,
    FAILED_TO_LOAD_DATA_GRID_MESSAGE,
    GRID_DEFAULT_PAGE_SIZE,
    GRID_DEFAULT_PAGE_SIZE_OPTIONS,
    Order,
    PAGE_TITLES,
    PAGINATION_DEFAULT_PAGE,
    SubscriptionType,
} from 'src/constants';
import { ToolBar } from 'src/components/ToolBar';
import { ToolBarItem } from 'src/components/ToolBar/components/ToolBarItem';
import { ReactComponent as SortingIcon } from 'src/assets/icons/sorting-icon.svg';
import { ReactComponent as FiltersIcon } from 'src/assets/icons/filters-icon.svg';
import { ToolBarModelType } from 'src/@types/tool-bar';
import { getSortingApiParams, getSortingSchema } from 'src/services/sorting-helpers';
import { getValidModel } from 'src/components/ToolBar/services/tool-bar-validators';
import { getModelFromQuery, makeUrlSearchParams } from 'src/services/query-helpers';
import { getFilteringSchema } from 'src/pages/udb/Subscriptions/schemas/filtering-schema';
import { getFiltersUdbApiParams } from 'src/services/filtering-helpers';
import { SearchSubscriptionsModel, SubscriptionSearchExpand } from 'src/@types/subscription-service-api';

export const enterpriseExpand: SubscriptionSearchExpand[] = [
    'domains',
    'owner.user',
    'customAttributes',
];

export const insiderExpand: SubscriptionSearchExpand[] = [
    'owner.user',
    'customAttributes',
];

const SubscriptionsPage: FC = () => {
    const [noDataMessage, setNoDataMessage] = useState<string>(DATA_GRID_NO_ROWS_MESSAGE);
    const [rows, setRows] = useState<SearchSubscriptionsModel[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [searchParams] = useSearchParams();

    const location = useLocation();
    const navigate = useNavigate();

    const page = Math.max(parseInt(`${searchParams.get('page')}`) || PAGINATION_DEFAULT_PAGE, 0);
    const pageSize = parseInt(searchParams.get('pageSize') ?? '0');
    const queryType: SubscriptionType = (searchParams.get('type') ?? '') as SubscriptionType;

    const [type, setType] = useState<SubscriptionType>(
        Object.values(SubscriptionType).includes(queryType) ? queryType : SubscriptionType.INSIDER,
    );

    const columns = getSubscriptionDataGridColumns(type);
    const sortingSchema = getSortingSchema(columns);
    const validSortingModel = getValidModel(getModelFromQuery('sorting', searchParams), sortingSchema);
    const validFiltersModel = getValidModel(getModelFromQuery('filters', searchParams), getFilteringSchema(type));

    const [filtersModel, setFiltersModel] = useState<ToolBarModelType>(validFiltersModel);
    const [sortingModel, setSortingModel] = useState<ToolBarModelType>(validSortingModel);

    const [pageState, setPageState] = useState<number>(page);
    const [pageSizeState, setPageSizeState] =
        useState<number>(GRID_DEFAULT_PAGE_SIZE_OPTIONS.includes(pageSize) ? pageSize : GRID_DEFAULT_PAGE_SIZE);

    const handleTabChange = (value: string | number) => {
        const newType = value as SubscriptionType;
        const newColumns = getSubscriptionDataGridColumns(newType);
        const newSortingSchema = getSortingSchema(newColumns);

        setType(newType);
        setRows([]);
        setPageState(PAGINATION_DEFAULT_PAGE);

        setSortingModel(getValidModel(getModelFromQuery('sorting', searchParams), newSortingSchema));
        setFiltersModel(getValidModel(getModelFromQuery('filters', searchParams), getFilteringSchema(newType)));
    };

    useEffect(() => {
        setFiltersModel(validFiltersModel);
    }, [JSON.stringify(validFiltersModel)]);

    useEffect(() => {
        setSortingModel(validSortingModel);
    }, [JSON.stringify(validSortingModel)]);

    useEffect(() => {
        setLoading(true);

        navigate({ ...location, search: makeUrlSearchParams({
            ...(Object.values(sortingModel).length && { sorting: encodeURI(JSON.stringify(sortingModel)) }),
            ...(Object.values(filtersModel).length && { filters: encodeURI(JSON.stringify(filtersModel)) }),
            ...(pageState !== PAGINATION_DEFAULT_PAGE && { page: pageState }),
            ...(pageSizeState !== GRID_DEFAULT_PAGE_SIZE && { pageSize: pageSizeState }),
            type,
        }).toString() });

        subscriptionsSearch(
            {
                pageSize: pageSizeState,
                page: pageState,
                filterBy: getFiltersUdbApiParams(filtersModel, type),
                orderBy: { activationDate: Order.DESC },
                ...getSortingApiParams(sortingModel),
            },
            type === SubscriptionType.INSIDER ? insiderExpand : enterpriseExpand,
        )
            .then((data) => {
                setRows(data.data);
                setTotalCount(data.totalCount);
                setNoDataMessage(DATA_GRID_NO_ROWS_MESSAGE);
            })
            .catch(() => {
                setRows([]);
                setTotalCount(0);
                setNoDataMessage(FAILED_TO_LOAD_DATA_GRID_MESSAGE);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [JSON.stringify(filtersModel), JSON.stringify(sortingModel), pageSizeState, pageState, type]);

    return (
        <BasicLayout pageVariant={PageVariant.DATA_GRID} testId="subscriptions-page">
            <Stack direction="row" marginBottom={{ xs: 2.5, sm: 3 }}>
                <PageTitle title={PAGE_TITLES.SUBSCRIPTIONS} />
            </Stack>
            <Stack data-testid="subscriptions-tabs" direction="row" justifyContent="space-between" alignItems="center">
                <TabBar
                    onChange={handleTabChange}
                    initialValue={type}
                    items={[
                        { label: 'Insider', value: SubscriptionType.INSIDER },
                        { label: 'Enterprise', value: SubscriptionType.ENTERPRISE },
                    ]}
                />
                <ToolBar>
                    <ToolBarItem
                        toolBarTitle="Filter conditions"
                        schema={getFilteringSchema(type)}
                        model={filtersModel}
                        setModel={(model) => {
                            setFiltersModel(model);
                            setPageState(PAGINATION_DEFAULT_PAGE);
                        }}
                        submitLabel="Apply filter"
                        clearLabel="Clear filter"
                        selectPlaceholder="Filter by"
                        toolBarButton={{
                            icon: <FiltersIcon />,
                            label: 'Filters',
                        }}
                    />
                    <ToolBarItem
                        toolBarTitle="Select sorting type"
                        schema={sortingSchema}
                        model={sortingModel}
                        setModel={(model) => {
                            setSortingModel(model);
                            setPageState(PAGINATION_DEFAULT_PAGE);
                        }}
                        submitLabel="Sort"
                        clearLabel="Clear"
                        selectPlaceholder="Sort by"
                        toolBarButton={{
                            icon: <SortingIcon />,
                            label: 'Sorting',
                        }}
                    />
                </ToolBar>
            </Stack>
            <DataGrid
                noRowsOverlayMessage={noDataMessage}
                getRowId={({ uuid }) => uuid}
                rows={rows}
                columns={columns.map((column) => ({ ...column, sortable: false }))}
                rowCount={totalCount}
                pageSize={pageSizeState}
                pageState={pageState}
                setPageState={setPageState}
                setPageSizeState={setPageSizeState}
                loading={isLoading}
                checkboxSelection
                smallHeight
            />
        </BasicLayout>
    );
};

export default SubscriptionsPage;
