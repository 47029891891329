import dayjs from 'dayjs';
import { MenuOption } from 'src/components/Menu/Menu';
import { BillingRecordModel, BillingRecordWithRenewal, SubscriptionModel } from 'src/@types/subscription-service-api';
import { RoundedBadgeProps } from 'src/components/Badge/RoundedBadge/RoundedBadge';
import { RoundedBadgeVariant } from 'src/components/Badge/RoundedBadge/RoundedBadge.styles';
import { BillingStatus, GeneralModalKey, InvoiceStatus, SubscriptionBillingType } from 'src/constants';

export const getPaymentStatusBadgeProps = (
    { invoiceStatus, cycleStart, stripeInvoiceID }: BillingRecordModel,
    { billingType }: SubscriptionModel,
): RoundedBadgeProps | null => {
    const currentTime = dayjs();

    if (billingType === SubscriptionBillingType.FREE) {
        return {
            label: BillingStatus.FREE,
            variant: RoundedBadgeVariant.OUTLINED,
        };
    }

    if (!stripeInvoiceID && currentTime.isBefore(dayjs(cycleStart))) {
        return {
            label: BillingStatus.UPCOMING,
            variant: RoundedBadgeVariant.BLUE,
        };
    }

    if (
        stripeInvoiceID &&
        invoiceStatus === InvoiceStatus.OPEN &&
        (currentTime.isAfter(dayjs(cycleStart)) || currentTime.isSame(dayjs(cycleStart)))
    ) {
        return {
            label: InvoiceStatus.PAST_DUE,
            variant: RoundedBadgeVariant.RED,
        };
    }

    switch (invoiceStatus) {
        case InvoiceStatus.PAID:
            return {
                label: InvoiceStatus.PAID,
                variant: RoundedBadgeVariant.GREEN,
            };
        case InvoiceStatus.OPEN:
            return {
                label: InvoiceStatus.OPEN,
                variant: RoundedBadgeVariant.BLUE,
            };
        case InvoiceStatus.UNCOLLECTIBLE:
            return {
                label: InvoiceStatus.UNCOLLECTIBLE,
                variant: RoundedBadgeVariant.GREY,
            };
        case InvoiceStatus.VOID:
            return {
                label: InvoiceStatus.VOID,
                variant: RoundedBadgeVariant.GREY,
            };
        case InvoiceStatus.DRAFT:
            return {
                label: InvoiceStatus.DRAFT,
            };
        default:
            return invoiceStatus && {
                label: invoiceStatus,
            };
    }
};

export const getUdbPaymentActions = (
    { renewal, stripeInvoiceID, invoiceStatus }: BillingRecordWithRenewal,
    { billingType }: SubscriptionModel,
    paymentStatus: string | null,
    canManage: boolean,
): MenuOption[] => {
    const isAutoRenewal = billingType === SubscriptionBillingType.AUTO_RENEWAL;

    const actions: MenuOption[] = [
        {
            label: 'View details',
            value: GeneralModalKey.billingDetailsModal,
        },
    ];

    if (!stripeInvoiceID && billingType !== SubscriptionBillingType.FREE && !isAutoRenewal) {
        actions.push({
            label: 'Attach an invoice',
            value: GeneralModalKey.attachAnInvoice,
        });
    }

    if (
        stripeInvoiceID &&
        billingType === SubscriptionBillingType.INVOICED &&
        invoiceStatus === InvoiceStatus.DRAFT
    ) {
        actions.push({
            label: 'Finalize invoice',
            value: GeneralModalKey.finalizeInvoiceModal,
        });
    }

    if (stripeInvoiceID && invoiceStatus !== InvoiceStatus.PAID && !isAutoRenewal) {
        actions.push({
            label: 'Update invoice',
            value: GeneralModalKey.updateInvoice,
        });
    }

    if (canManage && !isAutoRenewal) {
        actions.push({
            label: 'Contract details',
            value: GeneralModalKey.contractDetailsModal,
        });
    }

    if (renewal && paymentStatus === BillingStatus.UPCOMING) {
        actions.push({
            label: `${renewal.disableRenewalNotifications ? 'Enable' : 'Disable'} renewal notifications`,
            value: GeneralModalKey.editBillingRenewalNotification,
        });
    }

    return actions;
};
