import React, { FC, Fragment } from 'react';
import { Box } from '@mui/material';
import { StripeAddress } from 'src/@types/sso-api';
import { InfoBanner } from 'src/components/InfoBanner';
import { useLookupNamesHook } from 'src/hooks';
import { getCountriesList } from 'src/services/user-profile-api';
import Line from 'src/components/Line';

type CustomerAddressProps = {
    address?: StripeAddress | null;
};

const CustomerAddress: FC<CustomerAddressProps> = ({ address }) => {
    if (!address) {
        return (
            <InfoBanner marginTop={1}>
                Billing address is missing. Invoice finalization will not be possible.
            </InfoBanner>
        );
    }

    const country = useLookupNamesHook(getCountriesList, address.country);

    const addressString = [
        address.line1,
        address.line2,
        address.city,
        address.state,
        address.postal_code,
        country,
    ]
        .filter((part) => !!part)
        .join(', ');

    return (
        <Fragment>
            <Box paddingY={1}>
                <Line />
            </Box>
            {addressString}
        </Fragment>
    );
};

export default CustomerAddress;
