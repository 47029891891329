import React from 'react';
import { useTheme } from 'styled-components';
import { StyledToastContainer } from './SnackbarContainer.styles';
import { ReactComponent as CloseIcon } from 'src/assets/icons/snackbar/snackbar-close.svg';
import { SNACKBAR_EXPIRATION_TIME } from 'src/constants';

type SnackbarContainerProps = {
    containerId?: string;
    autoClose?: number;
};

export const SnackbarContainer = ({
    containerId = 'snackbar-container',
    autoClose = SNACKBAR_EXPIRATION_TIME,
}: SnackbarContainerProps) => {
    const theme = useTheme();

    return (
        <StyledToastContainer
            position="bottom-left"
            closeOnClick={false}
            containerId={containerId}
            autoClose={autoClose}
            mainTheme={theme}
            icon={false}
            closeButton={({ closeToast }) => (
                <button className="Toastify__close-button" onClick={closeToast}>
                    <CloseIcon />
                </button>
            )}
            data-testid="snackbar-container"
            hideProgressBar
        />
    );
};
