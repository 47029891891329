import { Stack, StackProps, Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledStack = styled(Stack)<StackProps & { 'data-selected': boolean }>`
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid ${({ theme, 'data-selected': selected }) => (selected ? theme.palette.mainBackgroundColor : theme.palette.borderGrey)};
    background-color: ${({ theme, 'data-selected': selected }) => (selected ? theme.palette.mainBackgroundColor : theme.palette.white)};
`;

export const IconContainer = styled(Stack)`
    svg {
        max-width: 100%;
    }
`;

export const StyledIconCaption = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
    }
`;
