import React, { FC, Fragment, ReactNode, useState } from 'react';
import { StyledTextButton } from './styles';
import { openLinkInANewTab } from 'src/services/location-manager';
import Link from 'src/components/Link/Link';
import { SnackbarMessageVariants } from 'src/constants';
import { useSnackbarMessage } from 'src/hooks';
import { getProfileBillingInvoice } from 'src/services/sso-api';
import type { BillingRecordModel } from 'src/@types/subscription-service-api';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { ReactComponent as StripeLinkIcon } from 'src/assets/icons/stripe-link-icon.svg';
import { buildStipeInvoiceHref } from 'src/services/stripe';

export type InvoiceLinkProps = {
    children: ReactNode;
    billingRecord: BillingRecordModel;
};

const InvoiceLink: FC<InvoiceLinkProps> = ({ children, billingRecord }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { addMessage } = useSnackbarMessage();

    const handeClick = () => {
        setIsLoading(true);
        getProfileBillingInvoice(billingRecord.subscriptionUUID, billingRecord.id)
            .then(({ hostedInvoiceURL }) => {
                if (!hostedInvoiceURL) {
                    return addMessage('There is no dedicated invoice link yet', SnackbarMessageVariants.WARNING);
                }

                openLinkInANewTab(hostedInvoiceURL);
            })
            .catch(() => addMessage('Failed to retrieve invoice', SnackbarMessageVariants.ERROR))
            .finally(() => setIsLoading(false));
    };

    return (
        <Fragment>
            {isLoading && <LoaderOverlay />}
            <StyledTextButton onClick={handeClick}>
                {children}
            </StyledTextButton>
        </Fragment>
    );
};

export const invoiceLinkRenderer = ({
    billingRecord,
    variant,
    isModal = false,
    testMode,
}: {
    billingRecord: BillingRecordModel;
    variant: 'udb' | 'profile';
    isModal?: boolean;
    testMode?: boolean;
}) => {
    const isUdb = variant === 'udb';
    const { stripeInvoiceID } = billingRecord;

    const Container: {
        Node: React.ElementType;
        props: Record<string, unknown>;
    } = isUdb ? {
        Node: Link,
        props: {
            'data-modal': isModal,
            href: stripeInvoiceID ? buildStipeInvoiceHref(stripeInvoiceID, testMode) : '',
            target: '_blank',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
        },
    } : {
        Node: InvoiceLink,
        props: {
            billingRecord,
        },
    };

    return (
        <Container.Node {...Container.props}>
            View invoice
            <StripeLinkIcon width="16px" />
        </Container.Node>
    );
};

export default InvoiceLink;
