import styled from 'styled-components';
import { Typography } from '@mui/material';
import { generalTableCellStyles } from 'src/components/TableSection/TableSection.styles';

export const StyledPaymentAmountInfo = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.black};
            
        ${generalTableCellStyles}
    }
`;

export const StyledStripeInvoiceID = styled(Typography)`
    && {
        overflow-wrap: break-word;
        
        ${generalTableCellStyles}
    }
`;

export const StyledPeriodInfo = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightGrey};

        @media (min-width: ${({ theme }) => theme.brakePoints.md}) {
            width: 130px;
        }
        
        ${generalTableCellStyles}
    }
`;

export const NoContractText = styled(Typography)`
    && {
        display: flex;
        align-items: center;
        gap: 8px;    
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: inherit;
        line-height: inherit;
        font-size: inherit;

        & svg {
            margin-top: -2px;
            width: 14px;
            height: 14px;

            @media (min-width: ${({ theme }) => theme.brakePoints.sm}) {
                    width: 16px;
                    height: 16px;
            }
        }    
    }
`;

export const RenewalIndexContainer = styled(Typography)`
    && {
        color: inherit;
        font-family: inherit;
        line-height: inherit;
        font-size: inherit;
        
        svg{
            margin-bottom: -5px;
            margin-left: 4px;
        }
    }
`;

export const StyledLinkContainer = styled.a`
    color: ${({ theme }) => theme.palette.snackbar.infoFont};
    font-family: ${({ theme }) => theme.fonts.medium};
    line-height: normal;
    word-break: break-all;
    display: flex;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    gap: 8px;

    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        padding-left: 10px;
        font-size: 14px;
        text-align: right;
    }
    
    & svg {
        margin-top: -2px;
        width: 14px;
        height: 14px;

        @media (min-width: ${({ theme }) => theme.brakePoints.sm}) {
            width: 16px;
            height: 16px;
        }
    }
`;

export const StyledPlainText = styled(Typography)`
    && {
        font-size: 16px;
        font-family: ${({ theme }) => theme.fonts.normal};
        color: ${({ theme }) => theme.palette.black};

        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 14px;
        }
    }
`;
