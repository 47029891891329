import React from 'react';
import { ReactComponent as ApplePayLogo } from 'src/assets/icons/cards/apple-pay.svg';
import { ReactComponent as GooglePayLogo } from 'src/assets/icons/cards/google-pay.svg';

const WalletType = {
    ApplePay: 'apple_pay',
    GooglePay: 'google_pay',
};

export const WalletTextMap: Record<string, string> = {
    [WalletType.ApplePay]: 'ApplePay',
    [WalletType.GooglePay]: 'GooglePay',
};

type WalletIconProps = {
    wallet?: string;
};

const WalletIcon: React.FC<WalletIconProps> = ({ wallet }) => {
    switch (wallet) {
        case WalletType.ApplePay:
            return <ApplePayLogo />;
        case WalletType.GooglePay:
            return <GooglePayLogo />;
        default:
            return null;
    }
};

export default WalletIcon;
