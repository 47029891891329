import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from 'styled-components';
import { BillingAddressLine } from './BillingAddress.styles';
import type { StripeAddress } from 'src/@types/sso-api';
import { TextButton } from 'src/components/Buttons';
import { FramedBox } from 'src/components/Containers';
import { useLookupNamesHook } from 'src/hooks';
import { getCountriesList } from 'src/services/user-profile-api';

type BillingAddressProps = {
    name?: string;
    address?: StripeAddress | null;
    onEditButtonClick?: () => void;
};

const BillingAddress: FC<BillingAddressProps> = ({ name, address, onEditButtonClick }) => {
    const theme = useTheme();

    const addressLines = [
        name,
        address?.line1,
        address?.line2,
        `${address?.city}, ${address?.state} ${address?.postal_code}`
            .replace(/[\s,]+$/, '')
            .replace(/^[\s,]+/, ''),
        useLookupNamesHook(getCountriesList, address?.country),
    ].filter(Boolean);

    return (
        <FramedBox paddingX={2} paddingY={1.5} data-testid="billing-address-container">
            <Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography fontSize={14} fontFamily={theme.fonts.bold}>
                        Billing address:
                    </Typography>
                    {onEditButtonClick && (
                        <Typography variant="button" fontSize={14} fontFamily={theme.fonts.medium}>
                            <TextButton onClick={onEditButtonClick} data-testid="billing-address-edit-button">
                                Edit
                            </TextButton>
                        </Typography>
                    )}
                </Stack>
                {addressLines.map((line, index) => (
                    <BillingAddressLine key={`address-line-${index}`} fontSize={14}>
                        {line}
                    </BillingAddressLine>
                ))}
            </Stack>
        </FramedBox>
    );
};

export default BillingAddress;
