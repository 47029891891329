import React, { FC, useEffect } from 'react';
import { useReloadPage, useSnackbarMessage } from 'src/hooks';
import { updateRenewal } from 'src/services/subscription-service-api';
import { useConfirmationModal } from 'src/hooks/useConfirmationModal';
import { BillingRecordWithRenewal } from 'src/@types/subscription-service-api';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import { SnackbarMessageVariants } from 'src/constants';

export type EditPaymentRenewalNotificationProps = {
    billingRecord: Required<BillingRecordWithRenewal>;
    uuid: string;
};

const EditPaymentRenewalNotification: FC<EditPaymentRenewalNotificationProps> = ({ billingRecord, uuid }) => {
    const { renewal, id } = billingRecord;

    const { reloadPage } = useReloadPage();
    const { addMessage } = useSnackbarMessage();
    const { modalId, updateModal } = useConfirmationModal({
        onConfirm: async () => {
            await updateRenewal(
                { billingRecordId: id, renewalId: renewal.id, uuid },
                { disableRenewalNotifications: !renewal.disableRenewalNotifications },
            )
                .then(reloadPage)
                .catch(() => addMessage('Failed to update renewal', SnackbarMessageVariants.ERROR));
        },
    });

    useEffect(() => {
        updateModal({
            title: `${renewal.disableRenewalNotifications ? 'Enable' : 'Disable'} notifications`,
        });
    }, []);

    return (
        <ConfirmationModal
            subtitle={
                `Are you sure you want to ${renewal.disableRenewalNotifications ? 'enable' : 'disable'} renewal notifications?`
            }
            confirmButtonText="Confirm"
            modalId={modalId}
        />
    );
};

export default EditPaymentRenewalNotification;
