import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { InvoiceModalContent } from './components';
import { StyledStack } from 'src/components/Modals/InvoiceModals/InvoiceModals.styles';
import { createSubscriptionBillingInvoice } from 'src/services/sso-api';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { SnackbarMessageVariants } from 'src/constants';
import { BillingRecordModel, BillingRecordWithRenewal } from 'src/@types/subscription-service-api';
import { PriceRow } from 'src/components/PriceRow';
import { Button } from 'src/components/Buttons';

export type UpdateInvoiceModalProps = {
    billingRecord: BillingRecordWithRenewal;
};

const UpdateInvoiceModal: FC<UpdateInvoiceModalProps> = ({ billingRecord: initialBillingRecord }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [billingRecord, setBillingRecord] = useState<BillingRecordWithRenewal>(initialBillingRecord);

    const { addMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();
    const { closeModal } = useGeneralModal();
    const {
        priceInCents,
        subscriptionUUID,
        id,
    } = billingRecord;

    const updateBillingRecordData = (updateData: Partial<BillingRecordModel>) => {
        setBillingRecord({ ...billingRecord, ...updateData });
        setIsSubmitDisabled(false);
    };

    const handleUpdateInvoice = () => {
        setIsLoading(true);
        return createSubscriptionBillingInvoice(subscriptionUUID, id, priceInCents)
            .then(() => {
                addMessage('Invoice successfully updated', SnackbarMessageVariants.SUCCESS);
                reloadPage();
                closeModal();
            })
            .catch((e) => {
                addMessage(e?.responseError?.data?.error, SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <StyledStack data-testid="update-invoice-modal">
            {isLoading && <LoaderOverlay />}
            <Stack spacing={{ xs: 1.5, sm: 2.5 }}>
                <InvoiceModalContent billingRecord={billingRecord} />
                <PriceRow
                    skipLine
                    title="Price:"
                    height={{ xs: 'unset', md: '60px' }}
                    handleSave={updateBillingRecordData}
                    finalPrice={priceInCents}
                    oldPrice={initialBillingRecord.priceInCents}
                />
                <Stack paddingTop={{ xs: 2.5, md: 3 }}>
                    <Button onClick={handleUpdateInvoice} fullWidth disabled={isSubmitDisabled}>
                        Update invoice
                    </Button>
                </Stack>
            </Stack>
        </StyledStack>
    );
};

export default UpdateInvoiceModal;
