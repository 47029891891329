export const getStripeDashboardBaseUrl = (isTestMode?: boolean) => (
    `https://dashboard.stripe.com${isTestMode ? '/test/' : '/'}`
);

export const buildStipeInvoiceHref = (stripeInvoiceID: string, isTestMode?: boolean) => {
    return `${getStripeDashboardBaseUrl(isTestMode)}invoices/${stripeInvoiceID}`;
};

export const buildStripeSubscriptionHref = (stripeSubscriptionId: string, isTestMode?: boolean) => {
    return `${getStripeDashboardBaseUrl(isTestMode)}subscriptions/${stripeSubscriptionId}`;
};

export const buildStripeCustomerHref = (stripeCustomerId: string, isTestMode?: boolean) => {
    return `${getStripeDashboardBaseUrl(isTestMode)}customers/${stripeCustomerId}`;
};
