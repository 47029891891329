import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { useModalWithAnchor } from 'src/hooks/useModalWithAnchor';
import { AssociatedLinkSection } from 'src/components/AssociatedLinkSection';
import { Scope, useUserCan } from 'src/hooks/useUserCan';
import StyledTooltip from 'src/components/Tooltip';
import { TextButton } from 'src/components/Buttons';
import { SubscriptionModel } from 'src/@types/subscription-service-api';
import { BadgeProps } from 'src/components/Badge/Badge/Badge';
import { RoundedBadgeProps } from 'src/components/Badge/RoundedBadge/RoundedBadge';
import { RoundedBadgeVariant } from 'src/components/Badge/RoundedBadge/RoundedBadge.styles';
import { BadgeVariant } from 'src/components/Badge/Badge/Badge.styles';
import type { ManageActionType } from 'src/components/ManageActions/ManageActions';
import { ManageActions } from 'src/components/ManageActions';
import { Badge, RoundedBadge } from 'src/components/Badge';
import { Accordion } from 'src/components/Accordion';
import { DateFormats, GeneralModalKey, Status, SubscriberType } from 'src/constants';
import { formatDate } from 'src/services/formatters';
import {
    SecondaryTitle,
    StyledGrid,
    StyledStack,
    SubscriptionInfo,
} from 'src/components/SubscriptionManageHeader/ManageHeader.styles';
import { User } from 'src/@types/unified-db-api';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit-icon.svg';

type ManageUserHeaderProps = {
    manageUser: User;
    actionsList: Record<string, ManageActionType>;
    subscriberType?: SubscriberType;
    activePaidSubscription?: SubscriptionModel;
    isEditable: boolean;
    isLoaded: boolean;
};

const badgePropsMapping: Record<SubscriberType, BadgeProps> = {
    [SubscriberType.insider]: {
        label: 'Insider',
        variant: BadgeVariant.BLUE,
    },
    [SubscriberType.enterprise]: {
        label: 'Enterprise',
        variant: BadgeVariant.GOLD,
    },
    [SubscriberType.basic]: {
        label: 'Basic',
    },
};

const roundedBadgePropsMapping: Record<Status, RoundedBadgeProps> = {
    [Status.ACTIVE]: {
        variant: RoundedBadgeVariant.GREEN,
        label: 'Active',
    },
    [Status.INACTIVE]: {
        label: 'Inactive',
    },
};

const ManageUserHeader: FC<ManageUserHeaderProps> = ({
    activePaidSubscription,
    subscriberType,
    manageUser,
    actionsList,
    isEditable,
    isLoaded,
}) => {
    const { status, uuid, fullName } = manageUser;
    const subscriptionPeriod = activePaidSubscription
        ? `
            ${formatDate(activePaidSubscription.activationDate, DateFormats.FULL)}
             -
            ${formatDate(activePaidSubscription.expirationDate)}`
        : '';

    const modalPropsGetter = () => ({
        extraProps: {
            name: fullName,
            uuid: uuid,
        },
    });

    const canReadCredentials = useUserCan(Scope.CREDENTIALS_READ);
    const { openModal } = useModalWithAnchor([GeneralModalKey.editUserName], isLoaded, modalPropsGetter);

    const openJobInformationModal = () => {
        openModal(GeneralModalKey.editUserName);
    };

    return (
        <StyledGrid data-testid="user-header" container alignItems={{ xs: 'flex-start', md: 'center' }} marginY={{ xs: 2, sm: 2.5 }}>
            <Grid item xs={12} sm={9}>
                <StyledStack direction="row" gap={1}>
                    <SecondaryTitle>{fullName}</SecondaryTitle>
                    {isEditable && (
                        <StyledTooltip arrow title="Edit user’s name">
                            <TextButton onClick={openJobInformationModal} data-testid="edit-user-btn">
                                <EditIcon />
                            </TextButton>
                        </StyledTooltip>
                    )}
                    {subscriberType && <Badge {...badgePropsMapping[subscriberType]} />}
                    <RoundedBadge {...roundedBadgePropsMapping[status]} />
                </StyledStack>
                {subscriberType && subscriberType !== SubscriberType.basic && !!subscriptionPeriod && (
                    <>
                        <StyledStack direction="row" display={{ xs: 'none', md: 'flex' }} marginTop={0.5}>
                            <SubscriptionInfo>
                                {`Subscription period:
                            ${subscriptionPeriod}`}
                            </SubscriptionInfo>
                        </StyledStack>
                        <StyledStack direction="row" display={{ xs: 'flex', md: 'none' }} marginTop={1}>
                            <Accordion
                                id="subscription-period"
                                title="Subscription period"
                            >
                                <SubscriptionInfo>
                                    {subscriptionPeriod}
                                </SubscriptionInfo>
                            </Accordion>
                        </StyledStack>
                    </>
                )}
            </Grid>
            <Grid
                item
                container
                xs={3}
                justifyContent="flex-end"
                position={{ xs: 'absolute', sm: 'relative' }}
                top={0}
                right={0}
            >
                <ManageActions isLoaded={isLoaded} uuid={uuid} actionsList={actionsList} />
            </Grid>
            {canReadCredentials && (
                <AssociatedLinkSection
                    href={`/credentials/${uuid}`}
                    linkText="Open associated credentials page"
                />
            )}
        </StyledGrid >
    );
};

export default ManageUserHeader;
