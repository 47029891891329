import { Box, MenuItem, MenuList, PopoverRoot, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import { inputStyles } from 'src/components/Inputs/StyledInput.styles';

export const StyledMenuItem = styled(MenuItem)`
    && {
        display: block;
        padding: 0 16px;
    }
`;

export const StyledMenuItemSpinner = styled(MenuItem)`
    && {
        display: block;
        padding: 24px 0;
    }
`;

export const StyledMenuList = styled(MenuList)`
    && {
        max-height: 300px;
        overflow-y: auto;
        padding: 0;
        max-width: 478px;

        & .MuiStack-root {
            overflow: hidden;
                
            & .MuiBox-root {
                overflow: hidden;
                        
                & .MuiTypography-root {
                   overflow: hidden;
                   text-overflow: ellipsis;
                }  
            }
        }
    }
`;

export const StyledSelect = styled(Select)`
    && {
        font-family: ${({ theme }) => theme.fonts.normal};
        
        ${inputStyles}
    }
`;

export const StyledSelectRoot = styled(Box)`
    && {
        min-height: ${({ theme }) => theme.sizes.inputHeight.default};

        & svg[data-testid="ArrowDropDownIcon"] {
            right: 10px;
            color: ${({ theme }) => theme.palette.black};
        }

        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
            min-height: ${({ theme }) => theme.sizes.inputHeight.xs};
            font-size: 14px;
            font-family: ${({ theme }) => theme.fonts.medium};
        }    
    }
`;

export const StyledSelectPopover = styled(PopoverRoot)`
    &&  {
        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
            background-color: rgba(38, 40, 49, 0.4);

            & .MuiPaper-root {
                position: fixed;
                top: auto !important;
                left: 0 !important;
                right: 0;
                bottom: 0;
                min-width: 100% !important;
            }
        }
    }  
`;

export const StyledSelectInput = styled(Box)`
    && {
        padding: 14px 16px;
        color: ${({ theme }) => theme.palette.black};

        & div {
            padding: 2px 0;
            overflow: hidden;
        }

        & > div:first-of-type {
            margin-right: 10px;
        }
            
        & .MuiStack-root {
            border: none;
        }
                
        & .MuiTypography-root:first-of-type {
            overflow: hidden;
            text-overflow: ellipsis;    
            font-family: ${({ theme }) => theme.fonts.demiBold};
        }

        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 14px;
            font-family: ${({ theme }) => theme.fonts.medium};
        }
    }
`;

export const StyledSelectHeader = styled(Grid)`
    color: ${({ theme }) => theme.palette.black};
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    & svg {
        display: inline-block;
        vertical-align: middle;
        max-height: 23px;
    }
`;
