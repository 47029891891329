import { useEffect, useState } from 'react';
import { getSubscriptionActions } from 'src/services/subscription-service-api';
import { getProfileSubscriptionActions } from 'src/services/sso-api';
import { SubscriptionActions } from 'src/@types/subscription-service-api';
import { useReloadPage, useSnackbarMessage } from 'src/hooks';
import { SnackbarMessageVariants, SubscriptionAction } from 'src/constants';

export const useSubscriptionActionProvider = (
    subscriptionUUID: string,
    mode: 'udb' | 'profile',
    shouldFetchActions: boolean,
) => {
    const [actions, setActions] = useState<SubscriptionActions>();
    const { addMessage } = useSnackbarMessage();
    const { pageReloadCount } = useReloadPage();

    const fetchSubscriptionActions = async () => {
        const fetcher = mode === 'udb' ? getSubscriptionActions : getProfileSubscriptionActions;
        return fetcher(subscriptionUUID)
            .then(({ actions: fetchedActions }) => fetchedActions)
            .then(setActions)
            .catch(() => {
                addMessage('Failed to load subscription actions', SnackbarMessageVariants.WARNING);
            });
    };

    const isActionAllowed = (actionKey: SubscriptionAction): boolean => {
        return !!actions?.[actionKey]?.allowed;
    };

    const getActionErrors = (actionKey: SubscriptionAction) => {
        return actions?.[actionKey]?.errors || null;
    };

    const getActionExtra = (actionKey: SubscriptionAction) => {
        return actions?.[actionKey]?.extra || null;
    };

    useEffect(() => {
        if (shouldFetchActions) {
            fetchSubscriptionActions();
        }
    }, [subscriptionUUID, mode, pageReloadCount, shouldFetchActions]);

    return {
        isActionAllowed,
        getActionErrors,
        getActionExtra,
    };
};
