import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { RootState } from 'src/redux/root-reducer';
import { NotFoundPage } from 'src/pages/NotFound';
import { ServerErrorPage } from 'src/pages/ServerError';
import { SnackbarMessageVariants, UserRoles } from 'src/constants';
import { useReloadPage, useSnackbarMessage } from 'src/hooks';
import { Spinner } from 'src/components/Spinner';
import { CredentialsDetailsSection } from 'src/pages/ManageCredentials/components/CredentialsDetailsSection';
import {
    getManageCredentialsActionsList,
} from 'src/pages/ManageCredentials/components/ManageCredentialsHeader/services/action-schema';
import { ManageCredentialsHeader } from 'src/pages/ManageCredentials/components/ManageCredentialsHeader';
import { searchCredentials } from 'src/services/sso-api';
import type { Credentials } from 'src/@types/credentials';
import PageTitle from 'src/components/PageTitle';
import { BasicLayout, CenteredFullScreenLayout } from 'src/layouts';
import { getUser } from 'src/services/unified-db-api';
import { User } from 'src/@types/unified-db-api';
import { useCanEditUser } from 'src/hooks/useCanEditUser';
import { CurrentUserState } from 'src/@types/redux';
import { userSelector } from 'src/redux/slices';

const ManageCredentials: FC = () => {
    const [error, setError] = useState<number>();
    const [credentials, setCredentials] = useState<Credentials>();
    const [user, setUser] = useState<User>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { uuid = '' } = useParams<{ uuid: string }>();
    const { addMessage } = useSnackbarMessage();
    const { pageReloadCount } = useReloadPage();
    const canEditUser = useCanEditUser(uuid);
    const { currentUser } = useSelector<RootState, CurrentUserState>(userSelector);
    const isAdmin = currentUser?.role === UserRoles.ADMIN;

    useEffect(() => {
        setIsLoading(true);

        Promise.all([
            searchCredentials({ fields: { uuid: uuid } }),
            getUser(uuid)
                .then((userData) => {
                    setUser(userData);
                    return userData;
                })
                .catch(() => null),
        ])
            .then(([credentialsResponse, userData]) => {
                if (!credentialsResponse.data[0]) {
                    setError(404);
                    return;
                }

                setCredentials(credentialsResponse.data[0]);
                if (!userData) {
                    addMessage('Failed to load user data', SnackbarMessageVariants.WARNING);
                }
            })
            .catch(({ responseError }) => {
                setError(responseError.status);
                addMessage('Failed to load data', SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [uuid, pageReloadCount]);

    if (error !== undefined) {
        return [404, 403].includes(error) ? <NotFoundPage /> : <ServerErrorPage />;
    }

    if (!credentials) {
        return (
            <CenteredFullScreenLayout>
                <Spinner />
            </CenteredFullScreenLayout>
        );
    }

    return (
        <BasicLayout testId="credentials-manage-page">
            <Box position="relative">
                <PageTitle title="Manage credentials" marginBottom={{ xs: 2.5, md: 3 }} />
                <ManageCredentialsHeader
                    credentials={credentials}
                    userName={user?.fullName}
                    isLoaded={!isLoading}
                    actionsList={getManageCredentialsActionsList(canEditUser, credentials, isAdmin)}
                />
                <CredentialsDetailsSection credentials={credentials} isLoading={isLoading} />
            </Box>
        </BasicLayout>
    );
};

export default ManageCredentials;
