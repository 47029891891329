import React, { FC } from 'react';
import { Button } from 'src/components/Buttons';
import { InvoiceIdInput } from 'src/components/Inputs/InvoiceIdInput';
import { InvoiceInfoBox } from 'src/components/InvoiceInfoBox';
import { StripeInvoice } from 'src/@types/sso-api';

type AttachExistingInvoiceProps = {
    invoiceData: StripeInvoice | null;
    isLoading: boolean;
    onSearchAgain: () => void;
    setInvoiceData: (invoice: StripeInvoice | null) => void;
    setIsLoading: (loading: boolean) => void;
    onAttachInvoice: () => void;
};

const AttachExistingInvoice: FC<AttachExistingInvoiceProps> = ({
    invoiceData,
    isLoading,
    onSearchAgain,
    setInvoiceData,
    setIsLoading,
    onAttachInvoice,
}) => (
    <>
        {!invoiceData && (
            <InvoiceIdInput
                label="Invoice"
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setInvoiceData={setInvoiceData}
            />
        )}
        {invoiceData && (
            <>
                <InvoiceInfoBox
                    marginBottom={3}
                    paddingTop={0}
                    invoiceData={invoiceData}
                    onSearchAgain={onSearchAgain}
                />
                <Button data-testid="attach-invoice-btn" onClick={onAttachInvoice} fullWidth>
                    Attach invoice
                </Button>
            </>
        )}
    </>
);

export default AttachExistingInvoice;
