import React, { FC, useState } from 'react';
import { EditJobInformationModalContent } from './components';
import { makeJobInformationConfirmMessages } from './components/JobInformationConfirmMessages';
import { ConfirmationMessages, ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import { BaseModal } from 'src/components/Modals/BaseModal';
import { prepareJobInfoRequestData } from 'src/services/request-data-formatter';
import { useReloadPage } from 'src/hooks/useReloadPage';
import {
    companyNameValidator,
    industryIdValidator,
    jobFunctionIdValidator,
    jobTitleValidator,
    validateSchemaObject,
} from 'src/services/validators';
import { updateUser } from 'src/services/unified-db-api';
import { JobInfo } from 'src/@types/unified-db-api';
import { FormWrapper } from 'src/components/Forms/components';
import { useGeneralModal, useIsSubmitDisabled, useSnackbarMessage } from 'src/hooks';
import { SnackbarMessageVariants } from 'src/constants';

export type JobInformationModalProps = {
    jobInformation?: Partial<JobInfo>;
    uuid: string;
};

export type JobInformationFormValues = Partial<JobInfo> & {
    industryId: string;
    jobFunctionId: string;
};

const EditJobInformationModal: FC<JobInformationModalProps> = ({
    jobInformation = {},
    uuid,
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [updateUserData, setUpdateUserData] = useState<Partial<JobInfo>>({});
    const [isConfirming, setIsConfirming] = useState<boolean>(false);

    const { isSubmitDisabled, onFormChangeHandler, forceChangeValues }
        = useIsSubmitDisabled<Partial<JobInfo>>(jobInformation);
    const { addMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();
    const { updateModal, closeModal } = useGeneralModal();

    const messages = makeJobInformationConfirmMessages(updateUserData, jobInformation);

    const handleCloseConfirmModal = () => {
        setIsConfirming(false);
    };

    const handleConfirm = () => {
        setIsSubmitting(true);
        updateUser(uuid, {
            jobInfo: updateUserData as JobInfo,
        })
            .then(() => {
                addMessage('User updated successfully', SnackbarMessageVariants.SUCCESS);
                reloadPage();
                closeModal();
            })
            .catch(() => {
                setIsConfirming(false);
                updateModal({ closeIsBlocked: false });
                addMessage('Failed to update User', SnackbarMessageVariants.ERROR);
            }).finally(() => {
                setIsSubmitting(false);
                setIsConfirming(false);
            });
    };

    const handleSubmit = (data: JobInformationFormValues) => {
        const updatedData = prepareJobInfoRequestData(data, jobInformation);
        setUpdateUserData(updatedData);
        setIsConfirming(true);
    };

    if (isConfirming) {
        return (
            <BaseModal
                step={1}
                handleGoBack={handleCloseConfirmModal}
                open={true}
                handleClose={handleCloseConfirmModal}
                title="Confirm changes"
            >
                <ConfirmationModal
                    modalId="confirm-edit-job-information-modal"
                    subtitle={<ConfirmationMessages messages={messages} />}
                    confirmButtonText="Confirm"
                    cancelButtonText="Cancel"
                    onConfirm={handleConfirm}
                    onCancel={handleCloseConfirmModal}
                />
            </BaseModal>
        );
    }

    return (
        <FormWrapper<JobInformationFormValues>
            onFormChange={onFormChangeHandler}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            initialValues={{
                companyName: jobInformation.companyName ?? '',
                jobTitle: jobInformation.jobTitle ?? '',
                industryId: jobInformation.industryId?.toString() ?? '',
                jobFunctionId: jobInformation.jobFunctionId?.toString() ?? '',
            } as JobInformationFormValues}
            validationSchema={validateSchemaObject({
                companyName: companyNameValidator.optional(),
                jobTitle: jobTitleValidator.optional(),
                industryId: industryIdValidator.optional(),
                jobFunctionId: jobFunctionIdValidator.optional(),
            })}
        >
            <EditJobInformationModalContent
                isSubmitDisabled={isSubmitDisabled || isSubmitting}
                onFormChangeHandler={onFormChangeHandler}
                forceChangeValues={forceChangeValues}
                updatedFormValues={updateUserData}
            />
        </FormWrapper>
    );
};

export default EditJobInformationModal;
