import React, { FC, ReactNode } from 'react';
import { BoxProps, Stack } from '@mui/material';
import { InfoBannerContainer } from './InfoBanner.styles';
import { ReactComponent as WarningIcon } from 'src/assets/icons/blue-warn-icon.svg';

type InfoBannerProps = {
    children: ReactNode;
    marginY?: BoxProps['marginY'];
    marginTop?: BoxProps['marginTop'];
};

const InfoBanner: FC<InfoBannerProps> = ({ children, marginY, marginTop }) => {
    return (
        <InfoBannerContainer
            padding={{ xs: 1.5, sm: 2 }}
            gap={{ xs: 1, sm: 1.25 }}
            marginTop={marginTop}
            marginY={marginY}
        >
            <Stack><WarningIcon /></Stack>
            {children}
        </InfoBannerContainer>
    );
};

export default InfoBanner;
