import React, { FC } from 'react';
import { Link } from '@mui/material';
import { useSelector } from 'react-redux';
import {
    StyledContent,
    StyledContentContainer,
    StyledCopyIconContainer,
    StyledGridContainer,
    StyledSubGrid,
} from './StripeLink.styles';
import { getStripeDashboardBaseUrl } from 'src/services/stripe';
import { ReactComponent as LinkIcon } from 'src/assets/icons/stripe-link-icon.svg';
import { EMPTY_CELL_VALUE } from 'src/services/grid-formatters';
import StyledTooltip from 'src/components/Tooltip';
import { CopyIcon } from 'src/components/CopyIcon';
import { RootState } from 'src/redux/root-reducer';
import { ConfigState } from 'src/@types/redux';
import { configSelector } from 'src/redux/slices';

type StripeLinkProps = {
    id: string;
    type: 'customers' | 'subscriptions';
};

const StripeLink: FC<StripeLinkProps> = ({ id, type }) => {
    const { testMode } = useSelector<RootState, ConfigState>(configSelector);
    const href = `${getStripeDashboardBaseUrl(testMode)}${type}/${id}`;

    if (id === EMPTY_CELL_VALUE) {
        return <span>{EMPTY_CELL_VALUE}</span>;
    }

    return (
        <StyledGridContainer alignItems="center" container>
            <StyledSubGrid item flex={1}>
                <StyledContentContainer direction="row" position="relative" spacing={2}>
                    <StyledTooltip placement="bottom-start" disableInteractive title={id} arrow>
                        <StyledContent target="_blank" href={href}>{id}</StyledContent>
                    </StyledTooltip>
                    <StyledTooltip disableInteractive arrow title="Open in Stripe">
                        <Link display="flex" target="_blank" href={href}>
                            <LinkIcon width="16px" />
                        </Link>
                    </StyledTooltip>
                </StyledContentContainer>
            </StyledSubGrid>
            <StyledCopyIconContainer item position="absolute" right={16}>
                <CopyIcon content={id} />
            </StyledCopyIconContainer>
        </StyledGridContainer>
    );
};

export const stripeLinkRenderer = (type: 'customers' | 'subscriptions', id: string) => {
    return <StripeLink id={id} type={type} />;
};
