import { emptyValueConditions, ToolBarConditionTypes } from 'src/constants';
import { ConditionModelType, SchemaType } from 'src/@types/tool-bar';

export const getDisabledConditions = (value: string, conditionsModel: ConditionModelType[]) => (
    conditionsModel
        .filter(([param]) => param === value)
        .map(([, conditionObject]) => Object.keys(conditionObject)[0])
);

export const hasParamWithoutCondition = (conditionsModel: ConditionModelType[]) => (
    conditionsModel.some(([, conditionObject]) => {
        return Object.entries(conditionObject)
            .some(([condition, value]) => !value && !emptyValueConditions.includes(condition));
    })
);

export const getParamOptions = (schema: SchemaType, conditionsModel: ConditionModelType[], currentValue: string) => {
    return Object.values(schema.fields)
        .sort((a, b) => a.order - b.order)
        .map(({
            label,
            value,
        }) => ({
            label,
            value,
            disabled: value !== currentValue && (
                getDisabledConditions(value, conditionsModel).length === schema.fields[value].conditionOptions.length
            ),
        }));
};

export const isCompatibleField = (
    oldField: string,
    newField: string,
    schema: SchemaType,
): boolean => {
    const { conditionType: oldType, valueOptions: oldOptions = [] } = schema.fields[oldField] || {};
    const { conditionType: newType, valueOptions: newOptions = [] } = schema.fields[newField] || {};

    if (oldType !== newType) {
        return false;
    }

    if (oldType === ToolBarConditionTypes.SELECT || oldType === ToolBarConditionTypes.MULTI_SELECT) {
        const oldValues = oldOptions.map((option) => option.value).sort();
        const newValues = newOptions.map((option) => option.value).sort();

        return JSON.stringify(oldValues) === JSON.stringify(newValues);
    }

    return true;
};
